@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latohairline.woff2') format('woff2'),
	url('../../fonts/latohairline.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latohairlineitalic.woff2') format('woff2'),
	url('../../fonts/latohairlineitalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latothin.woff2') format('woff2'),
	url('../../fonts/latothin.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latothinitalic.woff2') format('woff2'),
	url('../../fonts/latothinitalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latolight.woff2') format('woff2'),
	url('../../fonts/latolight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latolightitalic.woff2') format('woff2'),
	url('../../fonts/latolightitalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoregular.woff2') format('woff2'),
	url('../../fonts/latoregular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoitalic.woff2') format('woff2'),
	url('../../fonts/latoitalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latomedium.woff2') format('woff2'),
	url('../../fonts/latomedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latomediumitalic.woff2') format('woff2'),
	url('../../fonts/latomediumitalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latosemibold.woff2') format('woff2'),
	url('../../fonts/latosemibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latosemibolditalic.woff2') format('woff2'),
	url('../../fonts/latosemibolditalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latobold.woff2') format('woff2'),
	url('../../fonts/latobold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latobolditalic.woff2') format('woff2'),
	url('../../fonts/latobolditalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoheavy.woff2') format('woff2'),
	url('../../fonts/latoheavy.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoheavyitalic.woff2') format('woff2'),
	url('../../fonts/latoheavyitalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoblack.woff2') format('woff2'),
	url('../../fonts/latoblack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Lato';
	src: url('../../fonts/latoblackitalic.woff2') format('woff2'),
	url('../../fonts/latoblackitalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}


@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/CeraPro-Regular.woff2') format('woff2'),
	url('../../fonts/CeraPro-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/CeraPro-Black.woff2') format('woff2'),
	url('../../fonts/CeraPro-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/CeraPro-Bold.woff2') format('woff2'),
	url('../../fonts/CeraPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/CeraPro-Medium.woff2') format('woff2'),
	url('../../fonts/CeraPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'PT Mono';
	src: url('../../fonts/ptmono.woff2') format('woff2'),
	url('../../fonts/ptmono.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'PT Mono';
	src: url('../../fonts/ptmonobold.woff2') format('woff2'),
	url('../../fonts/ptmonobold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}


