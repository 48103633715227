.activation-form {
	
	&_line {
		margin-bottom: 24px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&_password {
		
		&__title {
			margin-bottom: 8px;
			color: #181920;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__field {
			margin-bottom: -20px;
		}
		
		&__forgot {
			display: flex;
			justify-content: flex-end;
			margin-top: 12px;
			
			a {
				color: #0055CB;
				font-size: 16px;
				line-height: 19px;
			}
		}
		
		&__description {
			margin-top: 8px;
			color: #B0B3C3;
			font-size: 14px;
			line-height: 20px;
		}
	}
}