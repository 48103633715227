// === Variables ==

// === Font ===
$fontSize: 16px;
$fontColor: #070717;
$fontWeight: 400;
$fontMain: 'Lato', Helvetica, Arial, sans-serif;
$fontSecond: 'Inter', 'Lato', Helvetica, Arial, sans-serif;
$lineHeight: 24px;

// === Color ===
$bgColor: #FCFCFC;
$gray: #F4F4F8;
$grayDark: #53546E;
$violet: #7950F2;
$blue: #228BE6;
$blueDark: #0D4CD3;
$orange: #FD7E14;
$dark: #101025;

// === Responsive breakpoints ===
$screen-container: 1280px;
$screen-container-padding: 32px;
$screen-xxl: 1279px;
$screen-xl: 1199px;
$screen-lg: 1023px;
$screen-md: 991px;
$screen-sm: 767px;
$screen-xs: 575px;
$screen-xss: 475px;
