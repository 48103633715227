.client-person {
	display: flex;

	&_left {
		flex: 1 1 auto;
		padding: 32px 32px 24px;
	}

	&_right {
		flex: 2 0 277px;
		max-width: 277px;
		padding: 26px 24px;
		border-left: 1px solid #E8E8EF;
	}

	&_head {
		display: flex;
		align-items: center;
		margin-bottom: 25px;
	}

	&_icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex: 2 0 40px;
		max-width: 40px;
		width: 40px;
		height: 40px;
		margin-right: 12px;
		font-weight: 900;
		font-size: 12px;
		line-height: 12px;
		border-radius: 100%;
	}

	&_name {
		color: #0C0D10;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
	}

	&_item {
		margin-bottom: 24px;

		&__head {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		&__icon {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			margin-right: 12px;
			font-weight: 900;
			font-size: 12px;
			line-height: 12px;
			border-radius: 100%;
		}

		&__name {
			color: #0C0D10;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
		}

		&__line {
			display: flex;
			align-items: flex-start;
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__title {
			flex: 2 0 124px;
			max-width: 124px;
			margin-right: 16px;
			color: #0C0D10;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}

		&__value {
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
		}
	}

	&_data-trusted {
		display: none;
		margin-top: 24px;

		&.is-show {
			display: block;
		}
	}

	&_trusted {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		padding-left: 16px;
		padding-right: 40px;
		color: #0C0D10;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		background-color: #F4F3F8;
		border: none;
		border-radius: 12px;
		transition: .3s ease;
		cursor: pointer;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 16px;
			bottom: 0;
			width: 16px;
			height: 16px;
			margin: auto;
			background: url("../../img/icons/arrow-small-down.svg") center no-repeat;
			transition: .3s ease;
		}

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}

		&.is-active {

			&:after {

				transform: rotate(-180deg);
			}
		}
	}

	&_login-title {
		margin-bottom: 24px;
		color: #0C0D10;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}

	&_login-data {
		margin-bottom: 24px;
	}

	&_login-item {
		margin-bottom: 24px;

		&__title {
			margin-bottom: 4px;
			color: #0C0D10;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}

		&__value {
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_acc {
		position: relative;
		padding: 26px 26px 24px 16px;
		border: 1px solid #E8E8EF;
		border-radius: 16px;
		//overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			top: 14px;
			left: 0;
			width: 40px;
			height: 64px;
			background: url("../../img/services/client-message.svg") center no-repeat;
		}

		&__title {
			margin-bottom: 36px;
			padding-left: 40px;
			color: #0C0D10;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
		}

		&__link {
			color: #0055CB;
			font-size: 16px;
			line-height: 24px;
			cursor: pointer;
		}
	}

	&_login-btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		padding: 0 16px;
		color: #0C0D10;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		background-color: #F4F3F8;
		border: none;
		border-radius: 12px;
		transition: .3s ease;
		cursor: pointer;

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}
	}

	@media (max-width: $screen-sm) {
		flex-direction: column;

		&_right {
			flex: 1 1 auto;
			max-width: 100%;
			padding: 32px 32px 24px;
			border-left: none;
			border-top: 1px solid #E8E8EF;
		}
	}

	@media (max-width: $screen-xs) {

		&_left,
		&_right {
			padding: 24px 24px 16px;
		}
	}
}

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 326px;
	/* height: 138px; */
	background-color: #fff;
	color: #000;
	text-align: center;
	border-radius: 8px;
	padding: 24px;
	position: absolute;
	z-index: 99;
	top: 150%;
	left: 50%;
	margin-left: -60px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	@media (max-width: $screen-xss) {
		width: auto;
	}
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 10%;
	margin-left: -5px;
	border-width: 10px;
	border-style: solid;
	border-color: transparent transparent white transparent;
}

//.tooltip.isToggle .tooltiptext {
//	visibility: visible;
//}

.tooltipIsToggle {
	visibility: visible !important;
}
