.services {

	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -6px;
	}

	&_item {
		position: relative;
		flex: 1 0 calc(33.33% - 12px);
		max-width: calc(33.33% - 12px);
		height: 265px;
		margin: 0 6px 12px;
		padding: 20px 0 0;
		background-color: rgba(255,255,255,.1);
		border: 1px solid transparent;
		border-radius: 16px;
		cursor: pointer;
		transition: .3s;

		&:hover {
			background: rgba(255, 255, 255, 0.2);
			border: 1px solid #ADF6FB;
		}

		&__info {
			padding-left: 24px;
			padding-right: 24px;
		}

		&__title {
			margin-bottom: 4px;
			color: #fff;
			font-family: $fontThird;
			font-size: 20px;
			font-weight: 700;
			line-height: 36px;
		}

		&__description {
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
		}

		&__img {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -32px;
			text-align: center;

			img {
				max-width: 100%;
			}
		}

		&__edit {
			position: absolute;
			top: 24px;
			right: 24px;
		}
	}

	@media (max-width: $screen-lg) {

		&_item {
			flex: 1 0 calc(50% - 12px);
			max-width: calc(50% - 12px);
			margin: 0 6px 44px;
		}
	}

	@media (max-width: $screen-md) {

		&_item {
			flex: 1 0 calc(50% - 16px);
			max-width: calc(50% - 16px);
			margin: 0 8px 48px;
		}
	}

	@media (max-width: $screen-xs) {

		&_list {
			margin: 0;
		}

		&_item {
			flex: 1 0 100%;
			max-width: 100%;
			margin: 0 0 64px;

			&__info {
				padding-left: 16px;
				padding-right: 16px;
			}

			&__title {
				margin-bottom: 4px;
				font-size: 20px;
				font-weight: 900;
				line-height: 24px;
			}

			&__description {
				font-size: 14px;
				font-weight: 500;
				line-height: 24px;
			}
		}
	}
}
