.site_footer {
	padding: 32px 24px;
	background-color: $gray;
	
	&__block {
		display: flex;
		justify-content: space-between;
	}
	
	@media(max-width: $screen-sm) {
		
		&__block {
			flex-direction: column;
			justify-content: flex-start;
			margin-top: 24px;
			
			&:first-child {
				margin-top: 0;
			}
			
			.footer-social {
				margin-top: 8px;
			}
			
			.footer-contact {
				margin-top: 24px;
			}
		}
	}
	
	@media(max-width: $screen-xs) {
		padding: 16px;
	}
}
