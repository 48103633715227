.icon-ya {
	display: inline-block;
	vertical-align: middle;
	width: 12px;
	height: 24px;
	background: url("../../img/icons/ya.svg") center no-repeat;
}

.icon-vk {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 14px;
	background: url("../../img/icons/vk.svg") center no-repeat;
}

.icon-ok {
	display: inline-block;
	vertical-align: middle;
	width: 15px;
	height: 24px;
	background: url("../../img/icons/ok.svg") center no-repeat;
}

.icon-fb-gray {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/fb-gray.svg") center no-repeat;
}

.icon-vk-gray {
	display: inline-block;
	vertical-align: middle;
	width: 21px;
	height: 19px;
	background: url("../../img/icons/vk-gray.svg") center no-repeat;
}

.icon-ok-gray {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/ok-gray.svg") center no-repeat;
}

.icon-fb-dark {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/fb-dark.svg") center no-repeat;
}

.icon-vk-dark {
	display: inline-block;
	vertical-align: middle;
	width: 21px;
	height: 19px;
	background: url("../../img/icons/vk-dark.svg") center no-repeat;
}

.icon-ok-dark {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/ok-dark.svg") center no-repeat;
}

.icon-fb-white {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/fb-white.svg") center no-repeat;
}

.icon-vk-white {
	display: inline-block;
	vertical-align: middle;
	width: 21px;
	height: 19px;
	background: url("../../img/icons/vk-white.svg") center no-repeat;
}

.icon-ok-white {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 16px;
	background: url("../../img/icons/ok-white.svg") center no-repeat;
}

.icon-gos {
	display: inline-block;
	vertical-align: middle;
	width: 102px;
	height: 17px;
	background: url("../../img/icons/gos.png") center no-repeat;
}

.icon-apple {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 24px;
	background: url("../../img/icons/apple.svg") center no-repeat;
}

.icon-attention {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/attention.svg") center no-repeat;
}

.icon-attention-white {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/attention-white.svg") center no-repeat;
}

.icon-support-blue {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/support-blue.svg") center no-repeat;
}

.icon-user {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/user.svg") center no-repeat;
}

.icon-gear {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/gear.svg") center no-repeat;
}

.icon-gear-white {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/gear-white.svg") center no-repeat;
}


.icon-gear-blue {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/gear-blue.svg") center no-repeat;
}

.icon-close {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/close.svg") center no-repeat;
}

.icon-close-white {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	background: url("../../img/icons/close-white.svg") center no-repeat;
}

.icon-laptop {
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	background: url("../../img/icons/laptop.svg") center no-repeat;
}

.icon-phone {
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	background: url("../../img/icons/phone.svg") center no-repeat;
}

.icon-arrow-profile {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 9px;
	background: url("../../img/icons/arrow-profile.svg") center no-repeat;
}

.icon-chat {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/chat.svg") center no-repeat;
}

.icon-chat-white {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/chat-white.svg") center no-repeat;
}

.icon-question {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/question.svg") center no-repeat;
}

.icon-edit {
	display: inline-block;
	vertical-align: middle;
	width: 12px;
	height: 12px;
	background: url("../../img/icons/edit.svg") center no-repeat;
}

.icon-change {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	background: url("../../img/icons/change.svg") center no-repeat;
}

.icon-exit {
	display: inline-block;
	vertical-align: middle;
	width: 15px;
	height: 16px;
	background: url("../../img/icons/exit.svg") center no-repeat;
}

.icon-device-blue {
	display: inline-block;
	vertical-align: middle;
	width: 14px;
	height: 16px;
	background: url("../../img/icons/device-blue.svg") center no-repeat;
}

.icon-trash {
	display: inline-block;
	vertical-align: middle;
	width: 13px;
	height: 14px;
	background: url("../../img/icons/trash.svg") center no-repeat;
}

.icon-save-disable {
	width: 25px;
	cursor: no-drop;
	height: 25px;
	background: url("../../img/icons/save-disabled.svg") center no-repeat;
}

.icon-save-active {
	width: 25px;
	cursor: pointer;
	height: 25px;
	background: url("../../img/icons/save-active.svg") center no-repeat;
}

.icon-log-out {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/log-out.svg") center no-repeat;
}

.icon-reload{
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/reload.svg") center no-repeat;
}

.icon-flash {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/flash.svg") center no-repeat;
}

.icon-link-to {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/link_to.svg") center no-repeat;
}

.icon-fb-light {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/fb-light.svg") center no-repeat;
}

.icon-tg-light {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/tg-light.svg") center no-repeat;
}

.icon-ok-light {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/ok-light.svg") center no-repeat;
}

.icon-vk-light {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/vk-light.svg") center no-repeat;
}

.icon-insta-light {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/insta-light.svg") center no-repeat;
}

.icon-copyright {
	width: 16px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 16px;
	background: url("../../img/icons/copyright.svg") center no-repeat;
}

.icon-copyright-gray {
	width: 16px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 16px;
	background: url("../../img/icons/copyright-gray.svg") center no-repeat;
}

.icon-success {
	width: 80px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 80px;
	background: url("../../img/icons/success.svg") center no-repeat;
}

.icon-menu {
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 20px;
	background: url("../../img/icons/menu.svg") center no-repeat;
}

.icon-refresh {
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 20px;
	background: url("../../img/icons/refresh.svg") center no-repeat;
}

.icon-link {
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 20px;
	background: url("../../img/icons/link.svg") center no-repeat;
}

.arrow-btn-right {
	display: inline-block;
	vertical-align: middle;
	width: 12px;
	height: 12px;
	background: url("../../img/icons/arrow-btn.svg") center no-repeat;
}

.arrow-btn-left {
	transform: rotate(180deg);
	display: inline-block;
	vertical-align: middle;
	width: 12px;
	height: 12px;
	background: url("../../img/icons/arrow-btn.svg") center no-repeat;
}

.arrow-blue-bottom {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../img/icons/arrow-blue-bottom.svg") center no-repeat;
}

.icon-info {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/info.svg") center no-repeat;
}

.icon-email {
	width: 24px;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: 24px;
	background: url("../../img/icons/email.svg") center no-repeat;
}
