.tools {

	&_title {
		font-weight: 700;
		font-size: 28px;
		line-height: 42px;
	}

	&_list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -16px;
		margin-right: -16px;
	}
	&_list_sm {
		display: none;
	}

	&_item {
		position: relative;
		flex: 1 1 calc(50% - 32px);
		max-width: calc(50% - 32px);
		margin: 32px 16px 0;
		padding: 24px;
		background: #fff;
		box-shadow: 0 6px 16px rgba(230, 235, 245, 0.8);
		border-radius: 16px;
		transition: .3s;
		// cursor: pointer;
		overflow: hidden;

		&:before,
		&:after {
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transition: .3s;
		}

		//&:hover {
		//
		//	&:after {
		//		visibility: visible;
		//		opacity: 0.12;
		//		z-index: 0;
		//	}
		//
		//	&:before {
		//		visibility: visible;
		//		opacity: 1;
		//		z-index: 0;
		//	}
		//
		//	.tools_item__img {
		//
		//		&--default {
		//			display: none;
		//		}
		//
		//		&--hover {
		//			display: block;
		//		}
		//	}
		//}

		&.violet-item {

			//&:hover {
			//
			//	&:before {
			//		background: $violet;
			//	}
			//
			//	&:after {
			//		background: $violet url("../../img/bg-pattern.png");
			//		background-size: cover;
			//	}
			//
			//	.tools_item__title,
			//	.tools_item__description {
			//		color: #fff;
			//	}
			//}

			.tools_item__title {
				color: $violet;
			}
		}

		&.blue-item {

			//&:hover {
			//
			//	&:before {
			//		background: $blue;
			//	}
			//
			//	&:after {
			//		background: $blue url("../../img/bg-pattern.png");
			//		background-size: cover;
			//	}
			//
			//	.tools_item__title,
			//	.tools_item__description {
			//		color: #fff;
			//	}
			//}

			.tools_item__title {
				color: $blue;
			}
		}

		&.orange-item {

			//&:hover {
			//
			//	&:before {
			//		background: $orange;
			//	}
			//
			//	&:after {
			//		background: $orange url("../../img/bg-pattern.png");
			//		background-size: cover;
			//	}
			//
			//	.tools_item__title,
			//	.tools_item__description {
			//		color: #fff;
			//	}
			//}

			.tools_item__title {
				color: $orange;
			}
		}

		&__block {
			position: relative;
			z-index: 2;
		}

		&__img {
			height: 184px;
			transition: .3s;

			&--default {
				display: block;
			}

			&--hover {
				display: none;
			}

			img {
				max-width: 100%;
			}
		}

		&__title {
			margin-top: 32px;
			font-weight: 700;
			font-size: 24px;
			line-height: 36px;
			transition: .3s;
		}

		&__description {
			margin-top: 12px;
			min-height: 72px;
			transition: .3s;
		}

		&__role {
			display: inline-block;
			margin-top: 12px;
			padding: 6px 12px;
			background-color: $gray;
			border-radius: 8px;
		}
	}

	@media(max-width: $screen-sm) {
		&_list_sm {
			display: none;
		}
	}
	@media(max-width: $screen-xss) {
		&_list {
			display: none;
		}
		&_item {
			flex: 1 1 auto;
			max-width: 100%;
			margin: 32px 0 0;
		}
		&_list_sm {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
