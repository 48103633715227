.copyright {

	&_info {
		display: flex;
		align-items: center;
	}

	&_item {
		position: relative;
		margin-right: 12px;
		padding-right: 14px;
		font-size: 12px;
		line-height: 20px;
		color: #7B819B;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 4px;
			height: 4px;
			margin: auto;
			background-color: #7B819B;
			border-radius: 100%;
		}

		&:last-child {
			margin-right: 0;
			padding-right: 0;

			&:after {
				display: none;
			}
		}
	}

	@media (max-width: $screen-md) {

		&_item {
			color: rgba(255,255,255,.7);
		}
	}

	@media (max-width: $screen-xs) {

		&_info {
			flex-direction: column;
			align-items: flex-start;
		}

		&_item {
			margin-right: 0;
			margin-bottom: 2px;

			&:after {
				display: none;
			}
		}
	}
}