.dropdown {
	position: relative;
	
	&.show-list {
		
		.dropdown_val {
			color: #0055CB;
			
			&:after {
				background: url("../../img/icons/arrow-dropdown-top.svg") center no-repeat;
			}
		}
		
		.dropdown_list {
			display: block;
		}
	}
	
	&_val {
		position: relative;
		display: inline-flex;
		align-items: center;
		height: 32px;
		padding-left: 20px;
		padding-right: 40px;
		color: #0055CB;
		font-size: 16px;
		line-height: 16px;
		border-radius: 6px;
		cursor: pointer;
		transition: .3s;
		
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 22px;
			bottom: 0;
			width: 8px;
			height: 4px;
			margin: auto;
			background: url("../../img/icons/arrow-dropdown-bot.svg") center no-repeat;
			transition: .3s;
		}
	}
	
	&_list {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 5;
		transform: translateY(4px);
		min-width: 148px;
		padding: 11px 0;
		background: #fff;
		border-radius: 8px;
		box-shadow: 0 2px 5px rgba(0,0,0,.2);
		
		&:after {
			content: '';
			position: absolute;
			top: -3px;
			right: 12px;
			width: 10px;
			height: 10px;
			background-color: #fff;
			transform: rotate(45deg);
			border-radius: 2px;
		}
	}
	
	&_item {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 40px;
		padding: 4px 20px;
		color: #111;
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;
		transition: .3s;
		
		&:hover {
			background: rgba(0, 85, 203, 0.1);
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&_val,
		&_item {
			font-size: 13px;
		}
		
		&_val {
			height: 25px;
		}
	}
}