.footer-social {

	.footer-contact_action {
		display: flex;
		justify-content: flex-end;
	}

	&_block {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 12px;

		&:first-child {
			margin-top: 0;
		}
	}

	&_title {
		color: $grayDark;
		font-family: $fontSecond;
		font-size: 15px;
		line-height: 24px;
		margin-right: 8px;
	}

	&_list {
		display: flex;
		margin: 0 -4px;
	}

	&_item {
		margin: 0 4px;
	}

	@media(max-width: $screen-sm) {

		&_block {
			justify-content: flex-start;
			margin-top: 8px;
		}
	}
}
