.btn {
	&-arrow {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		margin: 0;
		padding-right: 16px;
		padding-left: 42px;
		color: #fff;
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
		background: #4C6EF5 left 16px center url("../../img/icons/arrow-left.svg") no-repeat;
		border: none;
		border-radius: 12px;
		cursor: pointer;
		transition: .3s;
	}

	&-box {
		padding-top: 14px;
		margin-top: auto;
	}

	&-row {
		display: flex;
		justify-content: center;
		padding-top: 24px;

		button, a {
			margin: 0 12px !important;
		}
	}

	&-right {
		display: flex;
		justify-content: flex-end;
		padding-top: 28px;
		padding-bottom: 20px;
	}

	&-icon {
		width: 20px;
		height: 20px;
		flex: 0 0 auto;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 6px;

		&.login {
			background-image: url("../../img/icons/log-in.svg");
			background-size: contain;
		}
	}

	&-ui {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		padding: 10px 16px 10px 16px;
		border-radius: 12px;
		outline: none;
		text-decoration: none !important;
		margin: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		justify-self: flex-start;
		align-self: flex-start;
		max-width: min-content;

		&.atom {
			background-color: #F1F4FF;
			color: #4C6EF5;
		}

		&.basic {
			background-color: #4C6EF5;
			color: #fff;
		}

		&.border {
			background: transparent;
			color: #3D5DE0;
			border: 1px solid #3D5DE0;
			padding: 11px 16px 12px 16px;
			font-size: 15px;
			font-weight: 500;
			line-height: 24px;

		}

		&.fullWidth {
			width: calc(33.33333% - 22px);
			max-width: inherit;

			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		&.offset {
			margin-top: 10px;

			&_right {
				margin-right: 16px;
			}
		}

	}
}
