.aside-login {
	padding: 32px;
	background: #fff;
	border-radius: 16px;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
	
	&_title {
		margin-bottom: 32px;
		color: #0C0D10;
		font-size: 32px;
		font-weight: 700;
		line-height: 36px;
	}
	
	&_action {
		margin-bottom: 24px;
		
		.btn {
			max-width: 100%;
			font-weight: 500;
		
			b {
				display: inline-block;
				margin-left: 3px;
				font-weight: 700;
			}
		}
	}
	
	&_region {
		margin-bottom: 32px;
		
		&__title {
			margin-bottom: 8px;
			color: #181920;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 48px;
			background-color: #F4F3F8;
			border: none;
			border-radius: 8px;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				background-color: #e6e5ea;
			}
			
			&:focus,
			&:active {
				outline: none;
			}
		}
	}
	
	&_info {
		display: flex;
		flex-direction: column;
	}
	
	&_link {
		margin-bottom: 16px;
		color: #0055CB;
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	@media (max-width: 1279px) {
		padding: 24px;
		
		&_title,
		&_region {
			margin-bottom: 24px;
		}
	}
	
	@media (max-width: 767px) {
		padding: 24px 16px;
	}
}