.aside-info {
	padding: 32px;
	background: #fff;
	border-radius: 16px;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);

	&_title {
		margin-bottom: 16px;
		color: #000;
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
	}

	&_description {
		margin-bottom: 16px;
		color: #000;
		font-size: 16px;
		line-height: 24px;
	}

	&_more {
		position: relative;
		display: inline-block;
		padding-right: 34px;
		color: #0055CB;
		font-size: 16px;
		cursor: pointer;
		line-height: 24px;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 16px;
			height: 16px;
			margin: auto;
			background: url("../../img/icons/arrow-blue-bottom.svg") center no-repeat;
		}
	}

	&_close {
		cursor: pointer;
		position: relative;
		display: inline-block;
		padding-right: 34px;
		color: #0055CB;
		font-size: 16px;
		line-height: 24px;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 16px;
			height: 16px;
			margin: auto;
			transform: rotate(180deg);
			background: url("../../img/icons/arrow-blue-bottom.svg") center no-repeat;
		}
	}

	&_default {
		cursor: pointer;
		position: relative;
		display: inline-block;
		padding-right: 34px;
		color: #0055CB;
		font-size: 16px;
		line-height: 24px;
	}

	@media (max-width: 1599px) {

		&_description {
			//max-height: 94px;
			//overflow: scroll;
		}
	}

	@media (max-width: 1279px) {
		padding: 24px;

		&_title {
			line-height: 24px;
		}

		&_description {
			max-height: 100%;
			margin-bottom: 8px;
		}
	}

	@media (max-width: 767px) {
		padding: 24px 16px;

		&_description {
			margin-bottom: 12px;
		}
	}

	@media (max-width: 425px) {
		&_description {

			ul {
				width: calc(100% - 14px);
				list-style: none;
			}

			ul, li {
				margin: 0;
				padding: 0;
			}

			li {
				left: 14px;
				position: relative;
			}

			ul.unset, ul.unset > li {
				margin: inherit;
				padding: inherit;
			}

			li:before {
				position: absolute;
				top: 2px;
				left: 1px;
				margin: 8px 0 0 -12px;
				vertical-align: middle;
				display: inline-block;
				width: 5px;
				border-radius: 10px;
				height: 5px;
				border: 1px solid #000;
				background: #fff;
				content: "";
			}
		}
	}
}
