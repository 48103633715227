.client-engineering {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 20px;
	background: #fff;
	border-radius: 16px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	&_img {
		margin-bottom: 36px;

		img {
			max-width: 100%;
		}
	}

	&_info {
		max-width: 475px;
		text-align: center;
	}

	&_title {
		margin-bottom: 24px;
		color: #111;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}

	&_description {
		margin-bottom: 24px;
		color: #181920;
		font-size: 16px;
		line-height: 24px;
	}

	&_btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		padding: 0 24px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		background-color: #0055CB;
		border: none;
		border-radius: 8px;
		transition: .3s;
		cursor: pointer;

		i {
			margin-right: 8px;
			transition: .4s;
		}

		&:hover {

			i {
				transform: rotate(360deg);
			}
		}

		&:focus,
		&:active {
			outline: none;
			background-color: darken(#0055CB, 10%);
		}
	}

	@media (max-width: $screen-xs) {
		padding: 32px 16px;

		&_btn {
			width: 100%;
		}
	}
}

.client-engineering-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 130px 245px;
	background: #fff;
	border-radius: 16px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

	@media (max-width: 900px) {
		padding: 60px 20px;
	}

	&_img {
		margin-bottom: 36px;

		img {
			max-width: 100%;
		}
	}

	&_info {
		max-width: 475px;
		text-align: center;
	}

	&_title {
		margin-bottom: 24px;
		color: #111;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}

	&_description {
		margin-bottom: 24px;
		color: #181920;
		font-size: 16px;
		line-height: 24px;
	}

	&_btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		padding: 0 24px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		background-color: #0055CB;
		border: none;
		border-radius: 8px;
		transition: .3s;
		cursor: pointer;

		i {
			margin-right: 8px;
			transition: .4s;
		}

		&:hover {

			i {
				transform: rotate(360deg);
			}
		}

		&:focus,
		&:active {
			outline: none;
			background-color: darken(#0055CB, 10%);
		}
	}

	@media (max-width: $screen-xs) {
		padding: 32px 16px;

		&_btn {
			width: 100%;
		}
	}
}
