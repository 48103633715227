.site_aside {
	position: fixed;
	top: 55px;
	right: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;
	max-width: $screen-container;
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 64px);
	margin: 0 auto;
	
	&.profile-aside {
		
		.profile-aside_back {
			position: relative;
			margin-bottom: 4px;
			font-size: 16px;
			line-height: 16px;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				text-decoration: underline;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: -18px;
				bottom: 0;
				width: 10px;
				height: 6px;
				margin: auto;
				transform: rotate(-90deg);
				background: url("../../img/icons/arrow-profile.svg") center no-repeat;
				background-size: cover;
			}
		}
		
		.help {
			
			.btn-outline {
				padding-left: 32px;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 2 0 450px;
		max-width: 450px;
		min-height: calc(100vh - 64px);
		margin-right: 32px;
		padding: 48px 42px 64px;
		background-color: #fff;
		border-radius: 16px;
		box-shadow: 0 0 56px rgba(0, 0, 0, 0.1);
		overflow-y: auto;
	}
	
	&__content {
		position: relative;
		
		.tab {
			margin-bottom: 12px;
			
			&_list {
				background-color: #F4F3F8;
			}
			
			&_item {
				color: #000;
				
				&:hover {
					background-color: #fff;
				}
			}
		}
	}
	
	&__title {
		margin-bottom: 64px;
		font-size: 32px;
		font-weight: 800;
		line-height: 36px;
	}
	
	&__login-title {
		margin-top: 24px;
		margin-bottom: 30px;
		color: #0C0D10;
		font-size: 48px;
		font-weight: 700;
		line-height: 52px;
	}
	
	.profile {
		margin-bottom: 48px;
	}
	
	.auth {
		margin-bottom: 40px;
	}
	
	.auth-form {
		max-width: 375px;
		margin-bottom: 32px;
	}
	
	&__bottom {
		
		.support {
			margin-bottom: 4px;
			
			&_info {
				margin-bottom: 0;
			}
		}
		
		.social {
			margin: 0 -6px 24px;
		}
	}

	@media (max-height: 850px) and (min-width: $screen-md + 1) {

		&__inner {
			padding: 42px;
		}

		.profile {
			margin-bottom: 24px;
		}
	}
	
	@media (max-width: $screen-lg) {

		&__inner {
			flex: 2 0 420px;
			max-width: 420px;
			padding: 32px 32px 48px;
		}
	}
	
	@media (max-width: $screen-md) {
		position: relative;
		top: 0;
		right: 0;
		order: -1;
		max-height: 100%;
		margin-top: 88px;

		&--content-center {

			.site_aside__content {
				flex-direction: column;
				align-items: center;

				.site_aside__title,
				.auth-form {
					flex: 1 0 auto;
					width: 100%;
				}
			}
		}
		
		&.profile-aside {
			
			.profile-aside_back,
			.profile-devices {
				flex: 1 0 100%;
				max-width: 100%;
			}
			
			.profile-aside_back {
				
				&:after {
					left: -14px;
				}
			}
			
			.site_aside__content {
				padding-bottom: 80px;
			}
			
			.help {
				margin-top: auto;
				
				.btn-outline {
					margin-bottom: 0;
					margin-top: 8px;
				}
			}
		}

		&__inner {
			flex: 1 1 auto;
			max-width: 100%;
			min-height: auto;
			margin-right: 0;
			padding: 32px 24px 40px;
		}
		
		&__content {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			width: 100%;
		}
		
		&__title {
			flex: 2 0 345px;
			max-width: 345px;
			margin-bottom: 32px;
			font-size: 24px;
			line-height: 28px;
		}
		
		&__login-title {
			flex: 1 0 100%;
			max-width: 100%;
			margin-top: 0;
			margin-bottom: 24px;
			font-size: 24px;
			line-height: 28px;
		}
		
		.profile {
			margin-bottom: 0;
		}
		
		.auth {
			flex: 2 0 330px;
			max-width: 330px;
			margin-bottom: 0;
		}
		
		.auth-form {
			flex: 2 0 345px;
			max-width: 345px;
			order: 3;
			margin-bottom: 0;
		}
		
		.help {
			flex: 1 1 250px;
			max-width: 250px;
			margin-bottom: 0;
		}
		
		&__bottom {
			display: none;
		}
	}
	
	@media (max-width: $screen-sm) {
		margin-top: 72px;
		
		&.profile-aside {
			
			.site_aside__content {
				flex-direction: row;
				justify-content: center;
				padding-bottom: 0;
			}
			
			.help {
				
				&_exit.btn-outline  {
					margin-top: 24px;
				}
			}
		}
		
		&__content {
			flex-direction: column;
			align-items: center;
		}
		
		&__title,
		.auth,
		.auth-form,
		.help,
		.profile {
			flex: 1 0 100%;
			max-width: 345px;
			width: 100%;
		}
		
		&__title {
			margin-bottom: 24px;
			font-size: 18px;
			line-height: 22px;
			
			br {
				display: none;
			}
		}
		
		.auth {
			order: 2;
			transform: translateY(0);
			margin-bottom: 24px;
		}
		
		.auth-form {
			order: 2;
			margin-bottom: 8px;
		}
		
		.help {
			display: flex;
			flex-direction: column;
			order: 3;
		}
	}
	
	@media (max-width: $screen-xs) {

		&--content-center {

			.site_aside__content {
				align-items: flex-start;
			}
		}
		
		&.profile-aside {
			
			&.is-dropdown {
				
				.profile_info,
				.help {
					display: none;
				}
			}
			
			.profile-aside_back,
			.profile-devices {
				max-width: max-content;
			}
			
			.profile-aside_back {
				padding-left: 14px;
				font-size: 13px;
				
				&:after {
					left: -8px;
				}
			}
			
			.help,
			.profile {
				flex: 1 1 100%;
				max-width: 100%;
				
				.btn-outline {
					padding-left: 24px;
				}
			}
		}

		&__inner {
			padding: 24px 8px;
		}

		&__content {
			align-items: flex-start;

			.tab {
				margin-bottom: 9px;
			}
		}

		&__title,
		.auth,
		.auth-form,
		.help {
			max-width: 100%;
		}
		
		&__title {
			padding: 0 8px;
		}

		&__login-title {
			padding: 0 8px;
			font-size: 18px;
			line-height: 22px;
		}
		
		.auth,
		.auth-from {
			
			.btn {
				max-width: 100%;
			}
		}
		
		.help {
			
			.btn-outline {
				max-width: 100%;
			}
		}
	}
}