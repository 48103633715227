.management {
	
	&_title {
		margin-bottom: 8px;
		padding-left: 16px;
		color: rgba(255,255,255,.8);
		font-style: italic;
		line-height: 14px;
	}
	
	&_item-device {
		position: relative;
		margin-bottom: 8px;
		padding: 24px 24px 16px;
		background-color: rgba(255,255,255,.1);
		
		&:first-child {
			border-radius: 16px 16px 0 0;
		}
		
		&:last-child {
			border-radius: 0 0 16px 16px;
		}
		
		&__title {
			margin-bottom: 8px;
			color: #fff;
			font-family: $fontThird;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
		}
		
		&__auth {
			display: flex;
			align-items: center;
			margin-bottom: 32px;
			
			span {
				display: inline-block;
				color: rgba(255,255,255,.6);
				font-size: 16px;
				line-height: 14px;
			}
			
			.auth-date {
				margin-left: 8px;
				color: #fff;
				font-size: 16px;
				font-weight: 700;
				line-height: 14px;
			}
		}
		
		&__marks {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -4px;
		}
		
		&__mark {
			position: relative;
			display: inline-flex;
			align-items: center;
			height: 32px;
			margin: 0 4px 8px;
			padding-left: 30px;
			padding-right: 16px;
			color: #000;
			font-size: 16px;
			font-weight: 500;
			line-height: 16px;
			background-color: #fff;
			border-radius: 16px;
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 12px;
				bottom: 0;
				width: 6px;
				height: 6px;
				margin: auto;
				border-radius: 100%;
			}
			
			&.mark-violet {
				
				&:before {
					background-color: #5C15B7;
				}
			}
			
			&.mark-orange {
				
				&:before {
					background-color: #EF6D21;
				}
			}
			
			&.mark-green {
				
				&:before {
					background-color: #0EB5BF;
				}
			}
		}
		
		&__edit {
			position: absolute;
			top: 24px;
			right: 24px;
		}
	}
	
	&_action {
		
		.btn-white {
			width: auto;
			padding: 0 24px;
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&_item-device {
			padding: 24px 16px;
			
			&__title {
				margin-bottom: 16px;
			}
			
			&__auth {
				flex-direction: column;
				align-items: flex-start;
				
				span {
					margin-bottom: 4px;
				}
				
				.auth-date {
					margin: 0;
				}
			}
		}
		
		&_action {
			
			.btn-white {
				width: 100%;
			}
		}
	}
}