.wifi-error {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 100%;
	padding: 24px 16px 16px;
	
	&_icon {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		
		img {
			max-width: 100%;
		}
	}
	
	&_title {
		margin-bottom: 16px;
		color: #0C0D10;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}
	
	&_action {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		
		.btn-outline {
			justify-content: center;
			max-width: 210px;
			color: #0970FF;
			border: 1px solid #C2DBFF;
			
			&:hover {
				background-color: #C2DBFF;
			}
		}
	}
	
	&_description {
		max-width: 250px;
		margin: 0 auto 16px;
		color: #7B819B;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
	}
	
	.wifi-help {
		margin-top: auto;
	}
}