.social {
	
	&--gray,
	&--white {
	
		.social_item {
			margin: 0 6px;
			
			&:hover {
				background-color: #F4F3F8;
			}
		}
	}

	&_list {
		display: flex;
		align-items: center;
	}
	
	&_item {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		margin: 0 5px;
		border: 1px solid #E8E8EF;
		border-radius: 100px;
		transition: .3s;
		
		&.ya {
			background: rgba(255, 0, 0, 0.1);
			
			&:hover {
				background: rgba(255, 0, 0, 0.15);
			}
		}
		
		&.vk {
			background: rgba(74, 118, 168, 0.1);
			
			&:hover {
				background: rgba(74, 118, 168, 0.15);
			}
		}
		
		&.ok {
			background: rgba(238, 130, 8, 0.1);
			
			&:hover {
				background: rgba(238, 130, 8, 0.15);
			}
		}
		
		&.gos {
			width: auto;
			padding: 0 16px;
			background: rgba(17, 17, 17, .05);
			
			&:hover {
				background: rgba(17, 17, 17, .1);
			}
		}
	}
}