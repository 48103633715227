.checkbox {
	
	&_label {
		position: relative;
		display: inline-flex;
		align-items: center;
		margin: 0;
		cursor: pointer;
		font-size: 0;
		
		&:hover {
			
			.checkbox_action {
				
				&:before {
					background: #DDDDDD;
				}
			}
		}
	}
	
	&_input {
		display: none;
		
		&:checked ~ .checkbox_action {
			
			&:before {
				background-color: #0055CB;
			}
			
			&:after {
				opacity: 1;
			}
		}
	}
	
	&_action {
		position: relative;
		display: inline-block;
		min-width: 16px;
		min-height: 16px;
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			transition: .3s;
		}
		
		&:before {
			width: 16px;
			height: 16px;
			background: #F4F3F8;
			border-radius: 4px;
		}
		
		&:after {
			top: 4px;
			left: 4px;
			width: 8px;
			height: 8px;
			background: #fff;
			border-radius: 2px;
			opacity: 0;
		}
	}
	
	&_title {
		display: inline-block;
		padding-left: 8px;
		color: #7B819B;
		font-size: 16px;
		line-height: 16px;
	}
}