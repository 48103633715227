.news {
	
	&_list {
		padding-bottom: 8px;
	}
	
	&_item {
		position: relative;
		margin-bottom: 8px;
		padding: 24px;
		background-color: #fff;
		border-radius: 16px;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		transition: .3s;
		
		&:hover {
			box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
		}

		&__title {
			margin-bottom: 16px;
			font-family: $fontThird;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
		}
		
		&__date {
			display: flex;
			align-items: center;
			margin-bottom: 40px;
			
			&-value {
				position: relative;
				padding-left: 16px;
				padding-right: 12px;
				color: #0055CB;
				font-size: 16px;
				font-weight: 500;
				line-height: 16px;
				
				&:before {
					content: '';
					position: absolute;
					top: 8px;
					left: 0;
					width: 4px;
					height: 4px;
					background-color: #ddd;
					border-radius: 100%;
				}
				
				&:first-child {
					padding-left: 0;
					
					&:before {
						display: none;
					}
				}
			}
		}
		
		&__description {
			padding-right: 66px;
			color: #111;
			font-size: 16px;
			line-height: 22px;
			opacity:  .8;
		}
	}
	
	&_action {
		display: flex;
		justify-content: center;
		
		.btn-white {
			width: auto;
			padding: 0 24px;
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&_item {
			padding: 24px 16px;
			
			&__date {
				flex-wrap: wrap;
				margin-bottom: 32px;
				
				&-value {
					margin-bottom: 8px;
				}
			}
			
			&__description {
				padding-right: 0;
			}
		}
		
		&_action {
			
			.btn-white {
				width: 100%;
			}
		}
	}
}