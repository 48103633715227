.device-service {
	
	&_list {
		padding: 0 8px;
	}

	&_item {
		margin-bottom: 8px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&.active-service {
			margin-left: -8px;
			margin-right: -8px;
			padding: 8px;
			border: 1px dashed rgba(($fontColor), .1);
			border-radius: 8px;
			
			.device-service_item__inner {
				box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			}
		}
		
		&.service-library {
			
			.device-service_item__title {
				
				&:before {
					background-color: #F2A657;
				}
			}
		}
		
		&.service-diary {
			
			.device-service_item__title {
				
				&:before {
					background-color: #7A229B;
				}
			}
		}
		
		&.service-portfolio {
			
			.device-service_item__title {
				
				&:before {
					background-color: #0055CB;
				}
			}
		}
		
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 52px;
			padding: 0 12px;
			background-color: #fff;
			border-radius: 8px;
		}
		
		&__add {
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 40px;
			color: rgba(17, 17, 17, 0.4);
			font-size: 12px;
			line-height: 16px;
			border-radius: 8px;
		}
		
		&__title {
			position: relative;
			padding-left: 18px;
			font-weight: 500;
			line-height: 20px;
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 6px;
				height: 6px;
				margin: auto;
				border-radius: 100%;
			}
		}
		
		&__action {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 16px;
			height: 12px;
			
			span {
				display: block;
				width: 100%;
				height: 2px;
				border-radius: 4px;
				background-color: #ddd;
			}
		}
	}
}