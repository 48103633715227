.client-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba((#111), .7);
	
	&_inner {
		position: relative;
		max-width: 460px;
		width: 100%;
		padding: 32px;
		background: #fff;
		border-radius: 12px;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	}
	
	&_close {
		position: absolute;
		top: 24px;
		right: 24px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		background: #F4F3F8;
		border-radius: 100%;
		cursor: pointer;
		
		&:hover {
			background-color: darken(#F4F3F8, 5%);
			
			i {
				transform: rotate(90deg);
			}
		}
		
		i {
			width: 12px;
			height: 12px;
			background-size: cover;
			transition: .3s;
		}
	}
	
	&_title {
		margin-bottom: 8px;
		color: #181920;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	
	&_subtitle {
		margin-bottom: 24px;
		color: #7B819B;
		font-size: 18px;
		line-height: 24px;
	}
	
	&_login {
		margin-bottom: 16px;
		
		&__title {
			color: #181920;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__value {
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
		}
	}
	
	&_form {
		margin-bottom: 24px;
	}
	
	&_action {
		
		&__blue {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 48px;
			padding: 0 20px;
			color: #fff;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			background-color: #0055CB;
			border: none;
			border-radius: 8px;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				background-color: darken(#0055CB, 10%);
			}
			
			&:focus,
			&:active {
				outline: none;
			}
		}

		&__disable {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 48px;
			padding: 0 20px;
			color: #181920;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			background-color: #f4f3f8;
			border: none;
			border-radius: 8px;
			transition: .3s;
			cursor: not-allowed;
		}
	}
	
	&_success {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 394px;
		
		&__info {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1 1 auto;
		}
		
		&__icon {
			margin-bottom: 40px;
		}
		
		&__title {
			color: #000;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__action {
			flex: 2 0 48px;
			max-height: 48px;
			margin-top: auto;
		}
		
		&__close {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 48px;
			padding: 0 20px;
			color: #0C0D10;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			background: #F4F3F8;
			border: none;
			border-radius: 8px;
			transition: .3s;
			cursor: pointer;
			
			&:hover {
				background-color: darken(#F4F3F8, 5%);
			}
			
			&:focus,
			&:active {
				outline: none;
			}
		}
	}
}
