.device {

	&_card {
		display: flex;
		flex-direction: column;
		height: 377px;
		padding: 24px 8px 16px;
		background-color: #F5F7FF;
		border-radius: 8px;
		overflow-y: auto !important;

		&--outline {
			background-color: transparent;
			border: 1px solid #eee;

			.device-service_item__inner {
				background-color: #F4F3F8;
			}
		}

		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 32px;
			margin-bottom: 20px;
			padding: 0 8px;
		}

		&__date {
			color: #7B819B;
			line-height: 16px;
		}

		&__name {
			margin-bottom: 8px;
			padding: 0 8px;
			font-weight: 700;
			line-height: 16px;
		}

		&__type {
			margin-bottom: 10px;
			padding: 0 8px;
			color: #7B819B;
			line-height: 16px;
		}

		&__parent {
			display: inline-block;
			align-self: flex-start;
			margin-left: 8px;
			margin-bottom: 24px;
			padding: 8px 12px;
			font-size: 14px;
			line-height: 16px;
			border-radius: 8px;
			color: #282828;
			background-color: #F4F3F8;


			&_active {
				color: #3DAF3A;
				background-color: rgba((#3DAF3A), .1) !important;
			}
		}

		&__date {
			margin-bottom: 8px;
			font-size: 16px;
			line-height: 16px;
		}
	}
}