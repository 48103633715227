.sec-help {
	padding: 24px 0 48px;
	
	&_inner {
		position: relative;
		padding: 24px;
		background: #fff;
		box-shadow: 0 6px 16px rgba(230, 235, 245, 0.8);
		border-radius: 16px;
		overflow: hidden;
	}
	
	&_link {
		display: inline-block;
		margin-top: 4px;
		color: $blueDark;
		font-weight: 700;
		font-size: 20px;
		line-height: 32px;
	}
	
	&_img {
		position: absolute;
		top: 0;
		right: 0;
	}
	
	@media (max-width: $screen-xl) {
		padding: 16px 0 64px;
	}
	
	@media(max-width: $screen-sm) {
		padding: 12px 0 48px;
		
		&_title,
		&_link {
			max-width: 155px;
		}
		
		&_img {
			right: -45px;
		}
	}
}