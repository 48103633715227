.client-contact {
  display: flex;
  padding: 24px;

  &_cell {
    margin-bottom: 16px;

    &__icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      top: 3px;
      margin-left: 9px;
      background: url("../../img/icons/alert-circle.svg") center no-repeat;
    }

    &__title {
      margin-bottom: 8px;
      color: #0C0D10;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    &__value {
      color: #7B819B;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_info {
    flex: 2 0 400px;
    max-width: 400px;
    margin-right: 40px;
  }

  &_item {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 12px;
      color: #0C0D10;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    &__value {
      margin-bottom: 8px;
      color: #7B819B;
      font-size: 16px;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_action {
    margin-left: auto;

    &__btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      color: #0C0D10;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      background-color: #F4F3F8;
      border: none;
      border-radius: 12px;
      transition: .3s ease;
      cursor: pointer;

      &:hover {
        background-color: darken(#F4F3F8, 5%);
      }

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}