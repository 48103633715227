.site {

	&_wrapper {
		position: relative;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		background-size: cover;
		overflow-x: hidden;
		background: url("../../img/images/header.png") 50% 100% no-repeat;
		//background: url("../../img/images/PatternNewYear.png") 50% 100% no-repeat;
		&_new-year {
			position: relative;
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			background-size: cover;
			overflow-x: hidden;
			background: url("../../img/images/PatternNewYear.png") 100% 100% repeat;
		}
		&_esia {
			position: relative;
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			background-size: cover;
			overflow-x: hidden;
			background: url("../../img/images/PatternNewYearEsia.svg") 100% 100% repeat;
		}
	}

	&_main {
		position: relative;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 1 auto;
		max-width: 100%;

		@media (max-width: 1279px) {
			display: block;
		}
	}
}

.relative {
	position: relative;
	z-index: 2;
}

.customButton {
	color: #fff !important;
	text-decoration: none!important;
	border: 1px solid #ffff !important;
	width: 200px!important;
	background: #0049ad00 !important;
}

.main-container {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	max-width: $screen-container;
	width: 100%;
	margin: 0 auto;
	padding-left: 32px;
	padding-right: 32px;

	@media (max-width: 1439px) {
		max-width: 1204px;
	}

	@media (max-width: 767px) {
		padding-left: 24px;
		padding-right: 24px;
	}
}
