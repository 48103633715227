.site_header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	width: 100%;
	padding: 32px 0;

	&__inner {
		display: flex;
		align-items: center;
	}
	
	.logo {
		margin-right: 12px;
	}
}