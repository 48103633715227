.activation-login {
	display: flex;
	align-items: center;
	padding: 24px 16px;
	color: #000;
	font-size: 16px;
	line-height: 24px;
	background-color: #F4F3F8;
	border-radius: 8px;
	
	&_title {
		margin-right: 5px;
	}
	
	&_value {
		font-weight: 700;
	}
}