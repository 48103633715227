.wifi-info {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 100%;
	padding: 96px 16px 16px;
	background: url("../../img/wifi/icon-wifi.svg") center 0 no-repeat;
	
	&_title {
		margin-bottom: 16px;
		color: #0C0D10;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}
	
	&_login {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		
		.btn {
			max-width: 210px;
			background: #0970FF;

			b {
				display: inline-block;
				margin-left: 3px;
				font-weight: 700;
			}
		}
	}
	
	&_description {
		max-width: 250px;
		margin: 0 auto 16px;
		color: #7B819B;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
	}
	
	.wifi-help {
		margin-top: auto;
	}
}
