.nav {
	
	&_list {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	
	&_item {
		margin: 0 12px;
		
		&__link {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			line-height: 16px;
			opacity: .7;
			transition: .3s;
			
			&:hover,
			&.is-active {
				opacity: 1;
			}
			
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	&_btn {
		display: none;
		width: 25px;
		height: 19px;
		position: absolute;
		top: 0;
		right: 16px;
		transform: rotate(0deg);
		transition: transform .5s ease-in-out;
		cursor: pointer;
		z-index: 101;
		
		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			left: 0;
			background: #fff;
			border-radius: 3px;
			opacity: 1;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
		}
		
		span:nth-child(1) {
			top: 0;
			transform-origin: left center;
		}
		
		span:nth-child(2) {
			top: 8px;
			transform-origin: left center;
		}
		
		span:nth-child(3) {
			top: 16px;
			transform-origin: left center;
		}
		
		&.is-active {
			position: fixed;
			top: 24px;
			
			span {
				background: $fontColor;
			}
		}
		
		&.is-active span:nth-child(1) {
			transform: rotate(45deg);
			top: 0;
			left: -3px;
		}
		
		&.is-active span:nth-child(2) {
			width: 0;
			opacity: 0;
		}
		
		&.is-active span:nth-child(3) {
			transform: rotate(-45deg);
			top: 18px;
			left: -3px;
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&.is-active {
			
			.nav_list {
				display: flex;
				right: 0;
			}
			
			.nav_substrate {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 99;
				width: 100vw;
				height: 100vh;
				background: rgba(0,0,0,0.2);
			}
		}
		
		&_btn {
			display: block;
		}
		
		&_list {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			position: fixed;
			height: 100vh;
			width: 280px;
			top: 0;
			right: -280px;
			padding: 50px 0 0;
			background: #fff;
			box-shadow: 0 0 30px rgba(0,0,0,.1);
			z-index: 100;
			transition: right .3s ease;
		}
		
		&_item {
			margin: 0 0 15px;
			
			&__link {
				color: $fontColor;
				font-size: 18px;
				font-weight: 700;
				line-height: 18px;
			}
		}
	}
}