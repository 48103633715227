.sec-additional {
	padding: 48px 0 24px;
	
	@media (max-width: $screen-xl) {
		padding: 32px 0 16px;
	}
	
	@media (max-width: $screen-sm) {
		padding: 24px 0 12px;
	}
}