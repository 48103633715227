.intro-form {
	position: relative;
	width: 385px;
	padding: 24px;
	background: #fff;
	box-shadow: 0 10px 10px -5px rgba(41, 41, 64, 0.04), 0px 20px 25px -5px rgba(41, 41, 64, 0.08);
	border-radius: 16px;

	&_title {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
	}

	&_action {
		margin-top: 16px;

		&__title {
			margin-bottom: 4px;
			color: #87879B;
		}

		&__btn {
			display: inline-flex;
			height: 48px;
			width: 100%;
			background: $gray url("../../img/gos.svg") center no-repeat;
			border: none;
			border-radius: 8px;
			cursor: pointer;

			&:focus,
			&:active {
				outline: none;
			}
		}
	}

	&_action_empty {
		margin-top: 16px;

		&__title {
			margin-bottom: 4px;
			color: #87879B;
		}

		&__btn {
			display: inline-flex;
			height: 48px;
			width: 100%;
			background: #fff;
			border: 1px solid #4C6EF5;
			border-radius: 12px;
			cursor: pointer;
			align-items: center;
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			color: #364FC7;
			padding-left: 16px;
			position: relative;
			& .arrow {
				background: url("../../img/right-arrow.svg") center no-repeat;
				position: absolute;
				width: 13px;
				height: 13px;
				right: 16px;
			}

			&:focus,
			&:active {
				outline: none;
			}
		}
	}

	&_help {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 16px;

		&__link {
			display: inline-block;
			margin-top: 16px;
			color: $blueDark;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	@media (max-width: $screen-sm) {
		width: 100%;
		max-width: 385px;
	}
}
