.footer-nav {
	display: flex;
	
	&_link {
		display: inline-block;
		margin-right: 20px;
		color: $dark;
		font-weight: 600;
		font-family: $fontSecond;
		font-size: 15px;
		line-height: 24px;
		
		&:last-child {
			margin-right: 0;
		}
	}
}