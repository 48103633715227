.client-profile {
	position: relative;
	background: #fff;
	border-radius: 16px;

	&_top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 32px 24px;
		@media (max-width: $screen-xss) {
			box-shadow: 0px 4px 32px rgba(16, 8, 46, 0.1);
		}
	}

	&_info {
		display: flex;
		align-items: center;
	}

	&_icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex: 2 0 72px;
		width: 72px;
		height: 72px;
		margin-right: 24px;
		color: #fff;
		font-weight: 900;
		font-size: 16px;
		line-height: 20px;
		background: #2B3858;
		border-radius: 100%;
	}

	&_name {
		color: #0C0D10;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}

	&_open_new {

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}

		&__icon {
			margin-right: 8px;
			margin-left: auto;
			cursor: pointer;
		}

		&__title {
			color: #000;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}

	&_activate {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 8px 12px;
		background-color: #F4F3F8;
		border: none;
		border-radius: 12px;
		transition: .3s ease;
		cursor: pointer;

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}

		&__icon {
			margin-right: 8px;
		}

		&__title {
			color: #000;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}

	&_exit {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 8px 12px;
		background-color: #F4F3F8;
		border: none;
		border-radius: 12px;
		transition: .3s ease;
		cursor: pointer;

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}

		&__icon {
			margin-right: 8px;
		}

		&__title {
			color: #000;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}

	&_menu {
		display: none;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		background-color: #F4F3F8;
		border: none;
		border-radius: 12px;
		transition: .3s ease;
		cursor: pointer;

		&:hover {
			background-color: darken(#F4F3F8, 5%);
		}

		&:focus,
		&:active {
			outline: none;
		}
	}

	&_nav {
		&_wrap {
			margin-top: 18px;
			&_header {
				font-weight: 600;
				font-size: 18px;
				line-height: 28px;
				@media (max-width: $screen-sm) {
					font-size: 18px;
				}
				@media (max-width: $screen-xs) {
					font-size: 16px;
				}
				@media (max-width: $screen-xss) {
					font-size: 16px;
				}
			}
			&_description {
				font-size: 15px;
				line-height: 24px;
				color: #7B819B;
				width: 60%;
				@media (max-width: $screen-sm) {
					font-size: 15px;
					padding-bottom: 10px;
				}
				@media (max-width: $screen-xs) {
					padding-bottom: 10px;
					font-size: 14px;
					width: 100%;
					line-height: normal;
				}
			}
		}
		&_countur {
			height: 133px;
			display: inline-flex;
			@media (max-width: $screen-sm) {
				height: inherit
			}
			@media (max-width: $screen-xs) {
			    height: inherit
			}
			@media (max-width: $screen-xss) {
				height: inherit;
				display: block;
				padding: 0 16px 16px;
			}
		}
		&_vector {
			background: url("../../img/vector.svg") no-repeat;
			@media (max-width: $screen-xss) {
				background: url("../../img/vector-xss.svg") no-repeat;
				height: 50px;
				margin-top: 0;
				width: 100px;
			}
			height: 100px;
			margin-top: 24px;
			width: 120px;
		}

		&__inner {
			display: flex;
			padding: 0 24px;
			border-top: 1px solid #F4F3F8;
		}

		&__close {
			display: none;
		}

		&__item {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 56px;
			margin-right: 4px;
			padding: 0 16px;
			border-bottom: 4px solid transparent;
			transition: .3s;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&:hover {

				.nav-icon {

					svg {
						fill: #181920;
					}
				}

				.nav-title {
					color: #181920;
				}
			}

			&.is-active {
				border-color: #2a3858;

				.nav-icon {

					svg {
						fill: #181920;
					}
				}

				.nav-title {
					color: #181920;
				}
			}

			&.log-out {
				display: none;
			}

			&.log-out-2 {
				display: none;
			}

			.nav-icon {
				width: 20px;
				height: 20px;
				margin-right: 10px;

				svg {
					fill: #7B819B;
					transition: .3s;
				}
			}

			.nav-title {
				color: #7B819B;
				font-size: 16px;
				line-height: 20px;
				transition: .3s;
			}
		}
	}

	@media (max-width: $screen-xss) {
		&_activate {
			width: 100%;
			align-items: center;
			justify-content: center;
		}
	}

	@media (max-width: $screen-sm) {

		&_check-screen {
			display: none;
		}

		&_menu {
			display: flex;
		}

		&_nav {
			display: none;

			&.is-active {
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				z-index: 11;
				background: rgba(0, 0, 0, 0.5);

				.client-profile_nav__inner {
					position: absolute;
					flex-direction: column;
					align-items: flex-start;
					z-index: 5;
					left: 0;
					right: 0;
					top: 0;
					width: 100%;
					height: 100%;
					padding: 16px 16px 16px 0;
					background: #fff;
					border-radius: 16px 16px 0 0;
				}
			}

			&__close {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: 32px;
				height: 32px;
				margin-left: auto;
				margin-bottom: 3px;
				background-color: #F4F3F8;
				border: none;
				border-radius: 100%;
				transition: .3s ease;
				cursor: pointer;

				&:hover {
					background-color: darken(#F4F3F8, 5%);
				}

				&:focus,
				&:active {
					outline: none;
				}

				i {
					width: 12px;
					height: 12px;
					background-size: cover;
				}
			}

			&__item {
				display: inline-flex;
				height: 34px;
				padding-left: 16px;
				margin-bottom: 14px;
				border-left: 2px solid transparent;
				border-bottom: 0;

				&.is-active {
					border-color: #0C0D10;

					.nav-icon {

						svg {
							fill: #0C0D10;
						}
					}

					.nav-title {
						color: #0C0D10;
					}
				}

				&.log-out {
					position: relative;
					display: flex;
					margin-top: 40px;
					justify-content: flex-start;

					&:before {
						content: '';
						position: absolute;
						top: -20px;
						left: 16px;
						width: calc(100vw - 32px);
						height: 2px;
						background-color: #F4F3F8;
					}
				}

				&.log-out-2 {
					position: relative;
					display: flex;
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		&_icon {
			flex: 2 0 60px;
			width: 60px;
			height: 60px;
			margin-right: 18px;
		}
	}
}
