.footer-contact {
	display: flex;
	align-items: flex-end;

	&_version {
		margin-right: 16px;
		color: #87879B;
		font-size: 12px;
		font-family: $fontSecond;
		line-height: 16px;
		margin-top: 24px;
	}

	&_btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		padding: 0 24px;
		color: $dark;
		font-weight: 500;
		font-size: 14px;
		font-family: $fontSecond;
		line-height: 20px;
		background-color: transparent;
		border: 1px solid #AEAEBC;
		border-radius: 6px;
		cursor: pointer;

		&:focus,
		&:active {
			outline: none;
		}
	}

	@media(max-width: $screen-sm) {
		justify-content: space-between;
	}

	@media (max-width: $screen-xss) {
		&_btn {
			width: 100%;
			display: inline-flex;
		}
	}
}
