.contact {
	
	&_inner {
		display: flex;
		align-items: center;
	}
	
	&_info {
		display: flex;
		margin-left: 8px;
		margin-bottom: 24px;
		
		.btn-outline {
			max-width: 100%;
			width: auto;
			height: 32px;
			margin: 0 4px;
			padding: 0 12px;
			border-radius: 8px;
		}
	}
	
	@media (max-width: $screen-xl) {
		
		&_inner {
			align-items: flex-start;
			flex-direction: column;
		}
		
		&_info {
			margin-left: 0;
			margin-bottom: 8px;
			
			.btn-outline {
				margin-left: 0;
				margin-right: 4px;
				margin-bottom: 8px;
				
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}