.alert-block {
    display: flex;
    align-items: center;
    min-width: 300px;
    min-height: 68px;
    border-radius: 8px;
    padding: 0 23px;
    z-index: 8;
}
.alert-block:hover {
    text-decoration: none;
    cursor: default;
}
.green-alert{
    background-color: #318C2E;
}
.blue-alert{
    background-color: #1F367D;
}
.orange-alert{
    background-color: #FF8E23;
}
.red-alert{
    background-color: #DE4D4D;
}
.alert-block-icon{
    margin-right: 8px;
}
.alert-block-text{
    display: inline-block;
}
.alert-block-value{
    vertical-align: top;
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}
.alert-block-close {
    margin-left: auto;
    padding-left: 10px;
    cursor: pointer;
}

.alertify-logs {
    z-index: 10;
}

.alertify-logs>* {
    box-shadow: none !important;
    z-index: 10;
}
.alertify-logs>*, .alertify-logs>.default{
    background: none !important;
    z-index: 10;
}
.icon-checkmark-circle {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    height: 20px;
    background: url("assets/img/icons/checkmark-circle.svg") center no-repeat;
}
.icon-alert-close {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    height: 20px;
    background: url("assets/img/icons/alert-close.svg") center no-repeat;
}
@media (max-width: 575px) {

    .alertify-logs {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
    }

    .alertify-logs>.show {
        width: 100%;
        margin-top: 0 !important;
        padding: 0 !important;
    }
}

button:focus {
    outline: none;
}
