.icon-vk {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background: url("../../img/social/vk.svg") center no-repeat;
}

.icon-ok {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background: url("../../img/social/ok.svg") center no-repeat;
}

.icon-tg {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	background: url("../../img/social/tg.svg") center no-repeat;
}