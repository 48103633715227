.sec-not-found {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding: 0 0 20px;
	background-color: $gray;
	
	.container {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	
	&_head {
		padding: 20px 0;
	}
	
	&_content {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		justify-content: center;
	}
	
	@media (max-width: $screen-sm) {
		padding: 0 0 32px;
	}
}