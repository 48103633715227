.modal {
	//display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba((#111), .7);
	
	&_inner {
		position: relative;
		background-color: #fff;
		border-radius: 24px;
		box-shadow: 0 32px 64px rgba(0, 0, 0, 0.1);
	}
	
	&_close {
		position: absolute;
		top: 40px;
		right: 32px;
		cursor: pointer;
		transition: transform .3s;
		
		&:hover {
			transform: rotate(90deg);
		}
	}
	
	&_content {
		min-width: 1260px;
	}
}

.modal {
	&_flex {
		display: flex;
	}
}