.sec-first {
	padding: 135px 0 72px;

	&_title,
	&_heading {
		color: #fff;
		font-size: 56px;
		font-weight: 900;
		line-height: 60px;
	}

	&_title {
		margin-bottom: 64px;
	}

	&_heading {
		margin-bottom: 8px;
	}

	&_role {
		display: flex;
		align-items: center;
		margin-bottom: 48px;

		&__description,
		&__info {
			font-family: $fontThird;
			font-size: 16px;
			font-weight: 700;
			line-height: 18px;
		}

		&__description {
			margin-right: 7px;
			color: #fff;
		}

		&__info {
			display: flex;
			align-items: center;
		}

		&__val {
			margin-right: 8px;
			color: #FFE712;
		}
	}

	@media (max-width: $screen-md) {
		padding: 72px 0;
	}

	@media (max-width: $screen-xs) {
		margin-bottom: 0;
		padding: 40px 0;

		&_title {
			margin-bottom: 16px;
		}

		&_title,
		&_heading {
			font-size: 24px;
			line-height: 28px;
		}

		&_role {
			margin-bottom: 16px;

			&__description,
			&__info {
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}

.sec-other {
	margin-bottom: 72px;

	&_title {
		margin-bottom: 48px;
		color: #fff;
		font-size: 56px;
		font-weight: 900;
		line-height: 60px;
	}

	@media (max-width: $screen-sm) {
		margin-bottom: 88px;
	}

	@media (max-width: $screen-xs) {
		margin-bottom: 40px;
		padding: 0;

		&_title {
			margin-bottom: 16px;
			font-size: 24px;
			line-height: 28px;
		}
	}
}

.sec-intro {
	padding: 92px 0 74px;

	&_inner {
		position: relative;
		display: flex;
		flex: 1 1 100%;
	}

	&_left {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100%;
		padding-right: 24px;
		max-width: 100%;
	}

	&_right {
		flex: 2 0 415px;
		max-width: 415px;
	}

 	&_aside {
		position: absolute;
		top: -25px;
		right: 0;
		z-index: 5;
		max-width: 416px;

		&__inner {
			position: relative;
		}

		&__img {
			position: absolute;
			z-index: 1;
			right: -172px;
			top: 260px;

			img {
				max-width: 100%;
			}
		}

		.aside-login {
			margin-bottom: 16px;
		}
	}

	&_title {
		margin-bottom: 8px;
		color: #fff;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px
	}

	&_description {
		max-width: 575px;
		margin-bottom: 32px;
		color: #E0EDFF;
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
	}

	&_img {
		position: relative;
		transform: translateX(-82px);

		&-mob {
			display: none;
		}

		img {
			max-width: 100%;
		}
	}

	@media (max-width: 1599px) {

		&_aside {
			top: 0;

			&__img {
				display: none;
			}
		}
	}

	@media (max-width: 1439px) {
		padding: 104px 0 85px;
	}

	@media (max-width: 1279px) {
		padding: 144px 0 15px;

		&_right {
			flex: 2 0 318px;
			max-width: 318px;
		}

		&_aside {
			max-width: 318px;
			top: -24px;
		}

		&_title {
			max-width: 338px;
			margin-bottom: 24px;
			font-size: 40px;
			line-height: 48px;
		}

		&_description {
			max-width: 338px;
			margin-bottom: 35px;
			font-size: 18px;
			line-height: 28px;
		}

		&_img {
			display: none;

			&-mob {
				display: block;
				transform: translateX(-62px);

				img {
					max-width: 536px;
				}
			}
		}
	}

	@media (max-width: 767px) {
		padding: 88px 0 32px;

		&_left {
			padding-right: 0;
		}

		&_right {
			display: none;
		}

		&_title {
			max-width: 100%;
			margin-bottom: 32px;
			font-size: 32px;
			line-height: 41px;

			br {
				display: none;
			}
		}

		&_description {
			display: none;
		}

		&_aside {
			position: relative;
			top: 0;
			max-width: 100%;
			margin-bottom: 21px;
		}

		&_img {

			&-mob {
				transform: translateX(0);
				text-align: center;

				img {
					max-width: 100%;
				}
			}
		}
	}
}

.sec-choice {
	position: relative;
	padding: 120px 0 0;

	&_title {
		position: relative;
		z-index: 2;
		margin-bottom: 24px;
		color: #fff;
		font-family: $fontThird;
		font-size: 44px;
		font-weight: 700;
		line-height: 60px;
	}

	&_action {
		position: relative;
		z-index: 2;
		margin-bottom: 64px;

		.btn-white {
			height: 48px;
			max-width: 370px;
			color: #048BA8;
			font-weight: 700;

			&:hover {
				background-color: #048BA8;
			}
		}
	}

	&_img {
		position: relative;
		z-index: 2;

		img {
			max-width: 450px;
			width: 100%;
		}
	}

	&_parallax {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		pointer-events: none;

		.parallax_list {
			width: 100%;
			height: 100%;
		}

		.parallax_item {
			position: absolute !important;
			z-index: 1;
			pointer-events: none;

			&.oval {
				top: auto !important;
				left: auto !important;
				right: 0;
				bottom: 0;
				z-index: 0;
			}

			&.el-1-sep {
				top: 95px !important;
				right: 120px;
				left: auto !important;
			}

			&.el-2-sep {
				top: 247px !important;
				right: 218.1px;
				left: auto !important;
			}

			&.el-3-sep {
				top: 700px !important;
				right: 130px;
				left: auto !important;
			}

			&.el-4-sep {
				top: 1100px !important;
				right: 330px;
				left: auto !important;
			}

			&.el-5-sep {
				top: 1090px !important;
				right: 640px;
				left: auto !important;
			}

			&.el-6-sep {
				top: 1090px !important;
				right: auto !important;
				left: 352px !important;
			}

			&.el-7-sep {
				top: 1000px !important;
				right: auto !important;
				left: 111px !important;
			}

			&.el-8-sep {
				top: 658px !important;
				right: auto !important;
				left: 131px !important;
			}

			&.el-9-sep {
				top: 112px !important;
				right: auto !important;
				left: 90px !important;
			}

			&.el-1 {
				top: 95px !important;
				right: 120px;
				left: auto !important;
			}

			&.el-2 {
				top: 175px !important;
				right: 175px;
				left: auto !important;
			}

			&.el-3 {
				top: 260px !important;
				right: 0;
				left: auto !important;
			}

			&.el-4 {
				top: 300px !important;
				right: 250px;
			}

			&.el-5 {
				top: 500px !important;
				right: 70px;
				left: auto !important;
			}

			&.el-6 {
				top: 590px !important;
				right: 120px;
				left: auto !important;
			}

			&.el-7 {
				top: 650px !important;
				right: 50px;
				left: auto !important;
			}

			&.el-8 {
				top: 790px !important;
				right: 0;
				left: auto !important;
			}
			&.el-11 {
				top: 95px !important;
				right: 120px;
				left: auto !important;
			}

			&.el-21 {
				top: 175px !important;
				right: 175px;
				left: auto !important;
			}

			&.el-31 {
				top: 100px !important;
				left: 100px !important;
			}

			&.el-41 {
				top: 300px !important;
				right: 250px;
			}

			&.el-51 {
				top: 500px !important;
				right: auto !important;
				left: 70px !important;
			}

			&.el-61 {
				top: 590px !important;
				right: 120px;
				left: auto !important;
			}

			&.el-71 {
				top: 650px !important;
				right: 50px;
				left: auto !important;
			}

			&.el-81 {
				top: 790px !important;
				right: 0;
				left: auto !important;
			}
		}
	}

	@media (max-height: 1200px) {

		&_parallax {

			.parallax_item {

				&.el-4-sep {
					top: 800px !important;
					right: 330px;
					left: auto !important;
				}

				&.el-5-sep {
					top: 790px !important;
					right: 640px;
					left: auto !important;
				}

				&.el-6-sep {
					top: 790px !important;
					right: auto !important;
					left: 352px !important;
				}

				&.el-7-sep {
					top: 740px !important;
					right: auto !important;
					left: 111px !important;
				}
			}
		}


	}


	@media (max-height: 850px) and (min-width: $screen-md + 1) {
		overflow: hidden;

		&_img {

			img {
				max-width: 350px;
			}
		}
	}


	@media (max-width: $screen-md) {
		margin-bottom: 40px;
		padding: 64px 0 130px;
		background: url("../../img/parallax-elements/bg-moible.svg") center bottom no-repeat;

		&_title {
			margin-bottom: 20px;
			text-align: center;
			font-size: 40px;
			line-height: 49px;
		}

		&_action {
			margin-bottom: 118px;
			display: flex;
			justify-content: center;

			.btn-white {

				max-width: 340px;
			}
		}

		&_img {
			text-align: center;
		}

		&_parallax {
			display: none;
		}
	}

	@media (max-width: $screen-sm) {
		margin-top: 64px;
		margin-bottom: 0;
		padding: 0 0 150px;
		background-position: center;
		background-size: contain;

		&_img {

			img {
				max-width: 400px;
			}
		}
	}

	@media (max-width: $screen-xs) {
		margin: 40px -8px 48px;
		padding: 0 8px 48px;
		background-size: 320px;
		background-position: center bottom;

		&_title {
			margin-bottom: 12px;
			font-size: 24px;
			line-height: 28px;
		}

		&_img {

			img {
				max-width: 250px;
			}
		}
	}
}

.sec-error {
	position: relative;
	width: 100%;
	padding: 165px 0 80px;

	&_inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_title {
		position: relative;
		z-index: 2;
		margin-bottom: 24px;
		color: #fff;
		font-family: $fontThird;
		font-size: 44px;
		font-weight: 700;
		line-height: 55px;
	}

	&_action {
		position: relative;
		z-index: 2;

		.btn-white {
			max-width: 370px;
			height: 48px;
			color: #048BA8;
			font-weight: 700;

			&:hover {
				background-color: #048BA8;
			}
		}
	}

	&_img {
		transform: translateX(32px);

		img {
			max-width: 100%;
		}
	}

	&_parallax {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		pointer-events: none;

		.parallax__list {
			width: 100%;
			height: 100%;
		}

		.parallax_item {
			position: absolute !important;
			z-index: 1;
			pointer-events: none;

			&.el-1 {
				top: 85px !important;
				left: 115px !important;
			}

			&.el-2 {
				top: 735px !important;
				left: 290px !important;
			}

			&.el-3 {
				top: 30px !important;
				left: auto !important;
				right: 180px !important;
			}

			&.el-4 {
				top: 350px !important;
				left: auto !important;
				right: 630px !important;
			}

			&.el-5 {
				top: 670px !important;
				left: auto !important;
				right: 0 !important;
			}
		}
	}

	&_mobile-line {
		display: none;
	}

	@media (max-width: $screen-lg) {

		&_img {

			img {
				max-width: 420px;
			}
		}

		&_parallax {
			pointer-events: none;

			.parallax_item {

				&.el-1 {
					left: 140px !important;
					img {
						max-width: 95px;
					}
				}

				&.el-2 {
					top: 520px !important;
					left: 80px !important;
					img {
						max-width: 255px;
					}
				}

				&.el-3,
				&.el-4,
				&.el-5 {
					img {
						max-width: 110px;
					}
				}

				&.el-3 {
					top: 100px !important;
					right: 80px !important;
				}

				&.el-4 {
					top: 260px !important;
					right: 330px !important;
				}

				&.el-5 {
					top: 460px !important;
					right: -32px !important;
				}
			}
		}
	}

	@media (max-width: $screen-md) {

		&_title {
			font-size: 24px;
			line-height: 31px;
		}

		&_action {

			.btn-white {
				max-width: 215px;
				height: 32px;
				font-size: 12px;
				line-height: 14px;

				i {
					height: 12px;
				}
			}
		}
	}

	@media (max-width: $screen-sm) {
		padding: 100px 0;

		&_inner {
			flex-wrap: wrap;
		}


		&_title {
			margin-bottom: 16px;
		}

		&_action {

			.btn-white {
				max-width: 300px;
				width: 100%;
				height: 48px;
				font-size: 16px;
				line-height: 19px;

				i {
					height: 12px;
				}
			}
		}

		&_info {
			flex: 1 1 auto;
			width: 100%;
		}

		&_img {
			order: -1;
			display: flex;
			flex: 1 1 auto;
			justify-content: flex-end;
			width: 100%;
			margin-bottom: 50px;
			padding-left: 24px;

			img {
				max-width: 500px;
				width: 100%;
			}
		}

		&_parallax {

			.parallax_item {

				&.el-1 {
					top: 80px !important;
					left: 20px !important;
				}

				&.el-2 {
					top: auto !important;
					left: 360px !important;
					bottom: 50px !important;
					img {
						max-width: 230px;
					}
				}

				&.el-3,
				&.el-4,
				&.el-5 {
					img {
						max-width: 58px;
					}
				}

				&.el-3 {
					top: 60px !important;
					right: 80px !important;
				}

				&.el-4 {
					right: 440px !important;
				}

				&.el-5 {
					top: 530px !important;
				}
			}
		}
	}

	@media (max-width: $screen-xs) {
		padding: 100px 8px 48px;

		&_action {
			margin-bottom: 32px;
		}

		&_img {
			padding-left: 8px;

			img {
				max-width: 280px;
			}
		}

		&_parallax {

			.parallax_item {

				&.el-1,
				&.el-2 {
					display: none;
				}

				&.el-3 {
					right: 40px !important;
				}

				&.el-4 {
					top: 195px !important;
					right: 225px !important;
				}

				&.el-5 {
					top: 320px !important;
					right: -16px !important;
				}
			}
		}

		&_mobile-line {
			display: flex;
			padding-left: 24px;
			align-items: center;
		}
	}
}
