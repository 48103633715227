.additional {
	
	&_title {
		font-weight: 700;
		font-size: 28px;
		line-height: 42px;
	}
	
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -16px;
		margin-right: -16px;
		padding-bottom: 20px;
		
		.additional_item {
			flex: 1 1 calc(50% - 32px);
			max-width: calc(50% - 32px);
			margin: 32px 16px 0;

			&_3 {
				flex: 1 1 calc(33.33333% - 32px);
				max-width: calc(33.33333% - 32px);
				display: flex;
			}

			&_1 {
				flex: 1 1 calc(100% - 32px);
				max-width: calc(100% - 32px);
				display: flex;
			}
		}
	}
	
	&_slider {
		display: none;
	}
	
	&_item {
		padding: 24px;
		background: #fff;
		box-shadow: 0 6px 16px rgba(230, 235, 245, 0.8);
		border-radius: 16px;
		
		&__inner {
			display: flex;
		}
		
		&__img {
			flex: 2 0 48px;
			max-width: 48px;
			width: 48px;
			margin-right: 24px;
		}
		
		&__title {
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
		}
		
		&__description {
			margin-top: 12px;
		}

		&__apps {
			margin-top: auto;
			padding-top: 20px;
		}

		&__button {
			text-decoration: none !important;
			display: inline-flex;
			align-items: center;
			border-radius: 12px;
			background: #F1F4FF;
			padding: 10px 16px;
			margin-top: 10px;
			color: #4C6EF5;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;

			svg {
				margin-right: 6px;
			}
		}

		&__info {
			display: flex;
			flex-direction: column;
		}
	}
	
	@media (max-width: $screen-xl) {
		
		&_slider {
			display: block;
			margin-top: 16px;
			margin-left: -8px;
			margin-right: -8px;
			
			.additional_item {
				max-width: 390px;
				margin: 0 8px;
				min-height: 250px;
			}
		}
		
		&_item {
			padding: 16px;
			
			&__img {
				margin-right: 16px;
			}
		}
	}
	
	@media (max-width: $screen-xs) {
		&_slider {
			margin-top: 8px;
			margin-left: -4px;
			margin-right: -4px;
			
			.additional_item {
				max-width: 250px;
				margin: 0 4px;
				min-height: 450px;
			}
		}
	}

	@media (max-width: $screen-lg) {
		&_list {
			flex-direction: column;

			.additional_item {
				width: calc(100% - 32px);
				max-width: inherit;
			}
		}
	}

	//$screen-xss
}
