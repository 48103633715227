.activation {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-color: #E5E5E5;
	
	&_inner {
		max-width: 456px;
		width: 100%;
		margin: 0 auto;
		padding-top: 48px;
	}
	
	&_block {
		margin-bottom: 32px;
		padding: 32px 40px;
		background-color: #fff;
		border-radius: 16px;
		box-shadow: 0 16px 24px #EEEDF2;
		
		&__status {
			display: flex;
			justify-content: center;
			margin-bottom: 40px;
		}
		
		&__logo {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto 40px;
			max-width: 256px;
			
			img {
				margin-right: 16px;
			}
			
			p {
				color: #000;
				font-size: 16px;
				font-weight: 700;
				line-height: 19px;
			}
		}
		
		&__title {
			margin-bottom: 32px;
			color: #181920;
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			text-align: center;
		}
		
		&__login {
			margin-bottom: 24px;
		}
		
		&__description {
			margin-bottom: 32px;
			color: #181920;
			font-size: 16px;
			line-height: 24px;
			
			p {
				margin-bottom: 12px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			
			a {
				color: #0055CB;
			}
		}
		
		&__form {
			margin-bottom: 40px;
		}
	}
	
	&_description {
		display: flex;
		align-items: flex-start;
		
		&__icon {
			margin-right: 10px;
		}
		
		&__txt {
			opacity: .3;
			color: #7B819B;
			font-size: 14px;
			line-height: 20px;
		}
	}
}