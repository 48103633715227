@import "../utilites/var";

html {
    width: 100%;
    height: 100%;
    font-size: 100.01%;
    scroll-behavior: smooth;

    body {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0 auto;
        color: $fontColor;
        font-size: $fontSize;
        font-family: $fontMain;
        font-weight: $fontWeight;
        line-height: $lineHeight;
        //background: $bgColor;
        overflow-x: hidden;
        // Минимальное разрешение для мобилки
        min-width: 320px;
    }
}

.container {
    max-width: $screen-container;
    margin-right: auto;
    margin-left: auto;
    padding-left: $screen-container-padding;
    padding-right: $screen-container-padding;

    @media (max-width: $screen-xs) {
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
}
