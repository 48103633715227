.client-pass-valid {

	&_item {
		display: flex;
		align-items: center;
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}

		&.is-active {

			.client-pass-valid_item__title {
				color: #181920;
			}
		}

		&__flash {
			position: relative;
			display: flex;
			align-items: center;
			flex: 2 0 10px;
			max-width: 10px;
			height: 20px;
			margin-right: 10px;
			margin-left: 2px;
			cursor: pointer;

			&:after {
				content: url(../../img/icons/flash.svg);
				cursor: pointer;
			}
		}

		&__type {
			position: relative;
			display: flex;
			align-items: center;
			flex: 2 0 10px;
			max-width: 10px;
			height: 20px;
			margin-right: 10px;
			margin-left: 2px;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 5px;
				height: 5px;
				margin: auto;
				background-color: #7B819B;
				border-radius: 100%;
			}
		}

		&__active {
			position: relative;
			display: flex;
			align-items: center;
			flex: 2 0 10px;
			max-width: 10px;
			height: 20px;
			margin-right: 10px;
			margin-left: 2px;

			&:after {
				//background-image: url(assets/img/images/background-footer.svg);
				content: url(../../img/icons/save.svg);
				//position: absolute;
				//top: 0;
				//left: 0;
				//right: 0;
				//bottom: 0;
				//width: 5px;
				//height: 5px;
				//margin: auto;
				//background-color: #7B819B;
				//border-radius: 100%;
			}
		}

		&__title {
			color: #7B819B;
			font-size: 14px;
			line-height: 20px;
			transition: .3s;
		}
	}
}
