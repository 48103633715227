.help {
	margin-bottom: 12px;
	
	&_exit {
		margin-top: 16px;
	}
	
	.btn-outline {
		margin-bottom: 8px;
		padding-left: 24px;
	}
	
	@media (max-width: $screen-md) {
		
		&_exit {
			position: absolute;
			left: 0;
			bottom: 0;
			width: auto;
			min-width: 225px;
		}
	}
	
	@media (max-width: $screen-sm) {
		
		&_exit {
			position: relative;
			width: auto;
			min-width: auto;
		}
	}
}