.block-selector-roles {
	opacity: 0;
	width: 89%;
	position: absolute;
	bottom: 14px;
	display: flex;
	flex-direction: column;
	background: #FFFFFF;
	box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 8px 16px rgb(0 0 0 / 10%);
	border-radius: 16px;
}

.block-line {
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
	padding: 12px 0 12px 12px;
	border-radius: 16px;
	border-bottom: 1px solid #F4F3F8;
	&:hover {
		background: #F4F3F8;
	}
	&:first-child {
		border-radius: 16px 16px 0 0
	}
	&:last-child {
		border-radius: 0 0 16px 16px;
		border-bottom: none;
	}
}

.block-selector-roles-open {
    opacity: 0;
    z-index: 5;
    animation: fadeIn 1s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.blure {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0c71ca;
    left: 0;
    top: 0;
    transition: opacity 1s;
    opacity: 0;
    z-index: 3;
}
.blure-open {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0c71ca;
    left: 0;
    top: 0;
    transition: opacity 1s;
    opacity: 0.3;
    z-index: 3;
}

.mouse-enter {
    height: 258px !important;
    z-index: 5;
}

.client-service {
margin-bottom: 24px;

&_nav {
    display: flex;
    align-items: center;
    &__item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        padding: 0 16px;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        margin-right: 8px;
        background-color: transparent;
        border-radius: 100px;
        transition: .3s;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &.is-active {
            color: #fff;
            background-color: #0C0D10;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

&_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}

    &_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
    }

&_item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 152px;
    width: 415px;
    padding: 32px 150px 32px 24px;
    background-color: #fff;
    box-shadow: 0 4px 32px rgba(16, 8, 46, 0.1);
    border-radius: 16px;
    overflow: hidden;

    @media (max-width: $screen-md) {
        width: auto;
        margin: 0 8px 16px 8px;
    }

    &__title {
        margin-bottom: 8px;
        color: #181920;
        font-size: 19px;
        font-weight: 700;
        line-height: 24px;
    }

    &__post {
        margin-bottom: 8px;
        font-size: 14px;
        color: #7B819B;
    }

    &__subtitle {
        color: #7B819B;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &__action {
        margin-top: auto;
    }

    &__btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 16px;
        color: #0C0D10;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        background-color: #F4F3F8;
        border: none;
        border-radius: 12px;
        transition: .3s ease;
        cursor: pointer;

        &:hover {
            background-color: darken(#F4F3F8, 5%);
        }

        &:focus,
        &:active {
            outline: none;
        }
    }
}

@media (max-width: $screen-sm) {

    .block-selector-roles {
        width: calc(100% - 32px);
    }

    &_list {

        .client-service_item {
            flex: 1 1 100%;
            max-width: 100%;
        }
    }
    .service_item_absolute {
        position: relative;
    }

    &_item {
        min-height: 160px;
        padding: 24px 150px 24px 16px;
    }
}

@media (max-width: $screen-xs) {

    &_nav {
        overflow: auto;
    }

    &_info {
        margin: 0 -8px;
    }
}
}
.service_item_wrapper {
    flex-basis: 50%;
    @media (max-width: $screen-sm) {
        flex-basis: 100%;
        height: 176px;
    }
    position: relative;
}

.service_item_wrapper_one {
    flex-basis: 100%;
    position: relative;
}

.service_item_absolute {
    position: absolute;
    z-index: 90; // more footer
}

.service_item_absolute_down {
    z-index: 3; // more footer
}

.service_item_absolute_up {
    z-index: 90;
}
