.footer-department {
	
	&_list {
		display: flex;
	}
	
	&_item {
		display: flex;
		align-items: center;
		margin-right: 35px;
		
		&__img {
			flex: 2 0 48px;
			max-width: 48px;
			width: 48px;
			margin-right: 12px;
			
			img {
				max-width: 100%;
			}
		}
		
		&__title {
			color: #87879B;
			font-size: 15px;
			font-family: $fontSecond;
		}
	}
	
	@media (max-width: $screen-xl) {
		
		&_list {
			flex-direction: column;
		}
		
		&_item {
			margin-top: 12px;
			margin-right: 0;
			
			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	@media (max-width: $screen-sm) {
		
		&_item {
			margin-top: 16px;
		}
	}
	
	@media(max-width: $screen-xs) {
		
		&_item {
			align-items: flex-start;
			
			&__title {
				max-width: 220px;
				
				br {
					display: none;
				}
			}
		}
	}
}