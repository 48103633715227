.wifi-success {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	justify-content: center;
	height: 100%;
	
	&_icon {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		
		img {
			max-width: 100%;
		}
	}
	
	&_title {
		margin-bottom: 16px;
		color: #0C0D10;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}
	
	&_action {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		
		.btn {
			max-width: 210px;
			background: #0970FF;
		}
	}
	
}