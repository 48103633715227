.support {
	
	&_info {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}
	
	&_phone,
	&_mail,
	&_title {
		color: #7B819B;
		font-size: 12px;
		line-height: 20px;
	}
	
	&_phone {
		position: relative;
		margin-right: 12px;
		padding-right: 14px;
		
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 4px;
			height: 4px;
			margin: auto;
			background-color: #7B819B;
			border-radius: 100%;
		}
	}

	@media (max-width: $screen-md) {

		&_phone,
		&_mail,
		&_title {
			color: rgba(255,255,255,.7);
		}
	}
}