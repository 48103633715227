.intro {
	
	&_slide {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 650px;
		padding: 32px;
		border-radius: 16px;
		background-color: rgba(0,0,0,.4);

		&__title {
			margin-bottom: 24px;
			color: #fff;
			font-family: $fontThird;
			font-size: 24px;
			font-weight: 700;
			line-height: 28px;
		}

		&__date {
			margin-bottom: 24px;
			color: #FFD600;
			font-size: 16px;
			font-weight: 700;
			line-height: 22px;
		}

		&__info {
			margin-bottom: 24px;
			color: #fff;
			font-size: 16px;
			line-height: 22px;
		}

		&__bottom {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		
		&__action {
			flex: 2 0 200px;
			max-width: 200px;
		}

		&__prof {
			flex: 1 1 auto;
			padding-left: 40px;
			text-align: right;

			&-name {
				margin-bottom: 8px;
				color: #fff;
				font-size: 14px;
				font-weight: 700;
				line-height: 24px;
			}

			&-post {
				color: rgba(255,255,255,.6);
				font-size: 12px;
				font-style: italic;
				line-height: 16px;
			}
		}
	}
	
	@media (max-width: $screen-md) {
		
		&_slide {
			
			&__prof {
				display: none;
			}
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&_slide {
			justify-content: flex-start;
			min-height: 560px;
			padding: 150px 16px 48px;
			
			&__title {
				margin-bottom: 32px;
				font-size: 20px;
				line-height: 24px;
				
				br {
					display: none;
				}
			}
			
			&__date {
				margin-bottom: 40px;
			}
			
			&__action {
				flex: 1 0 100%;
				max-width: 100%;
				
				.btn-white {
					max-width: 100%;
				}
			}
		}
	}
}