.site_footer {
	position: relative;
	z-index: 10;
	display: none;
	margin-top: auto;
	padding: 40px 32px 60px;
	background: #0C0D10;

	&__inner {
		display: flex;
		align-items: center;
	}

	&__left {
		margin-right: 26px;
	}

	.support {

		&_info {
			margin-bottom: 4px;
		}
	}

	.social {
		margin-left: -6px;

		&_item {
			border-color: #fff;
		}
	}

	.contact {
		position: absolute;
		top: 24px;
		right: 64px;

		&_info {

			.btn-outline {
				margin: 0 0 0 8px;
				color: #fff;
				background-color: rgba(255,255,255,.1);
				border: none;
			}
		}
	}

	@media (max-width: $screen-md) {
		display: block;
	}

	@media (max-width: $screen-sm) {

		.contact {
			position: relative;
			top: 0;
			right: 0;

			&_info {
				margin-top: 24px;
				margin-bottom: 0;

				.btn-outline {
					margin: 0 8px 0 0;
				}
			}
		}
	}

	@media (max-width: $screen-xs) {
		padding: 40px 24px 48px;

		&__inner {
			flex-direction: column;
			align-items: flex-start;
		}

		&__left {
			margin-right: 0;
			margin-bottom: 24px;
		}

		.contact {

			&_info {
				width: 100%;
				flex-direction: column;

				.btn-outline {
					max-width: 100%;
					margin: 0 0 8px 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.footer {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 80px;
	padding: 20px 36px;
	background-color: #25314f;
	background-image: url("../../img/images/background-footer.svg");
	background-position: 50%;
	background-size: contain;
}