.profile {
	
	&_head {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 56px;
	}
	&_card__parent {
		display: inline-block;
		align-self: flex-start;
		padding: 8px 12px;
		font-size: 14px;
		line-height: 16px;
		border-radius: 8px;
		color: #282828;
		background-color: #F4F3F8;
	}
	
	&_title {
		margin-bottom: 20px;
		color: #111;
		font-size: 32px;
		font-weight: 900;
		line-height: 38px;
	}
	
	&_name {
		margin: 24px 0 32px;
	}
	
	&_post {
		font-size: 16px;
		line-height: 16px;
	}
	
	&_more {
		display: none;
	}
	
	&_description {
		margin-bottom: 32px;
		font-size: 16px;
		line-height: 19px;
	}
	
	&_role {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -4px;
		
		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			flex: 1 0 calc(50% - 8px);
			min-height: 240px;
			margin: 0 4px 8px;
			padding: 16px 24px;
			background: #F4F3F8;
			border-radius: 12px;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				text-decoration: none;
				box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
				transform: translateY(-4px);
			}
			
			&-title {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
				text-align: center;
				line-height: 20px;
			}
		}
	}
	
	&_info {
		
		&__item {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		
			&-title {
				flex: 2 0 80px;
				max-width: 80px;
				margin-right: 8px;
				color: #9CA0B4;
				font-size: 16px;
				font-weight: 400;
				line-height: 18px;
			}
			
			&-val {
				margin-right: 12px;
				font-size: 16px;
				line-height: 18px;
			}
			
			&-edit {
				cursor: pointer;
			}
		}
	}

	@media (max-height: 850px) and (min-width: $screen-md +1) {

		&_head {
			margin-bottom: 25px;
		}
	}
	
	@media (max-width: $screen-md) {
		flex: 1 0 calc(100% - 250px);
		max-width: calc(100% - 250px);
		
		&_head {
			margin-bottom: 0;
		}
		
		&_title {
			margin-bottom: 32px;
			padding-right: 0;
			font-size: 24px;
			line-height: 28px;
			
			br {
				display: none;
			}
		}
		
		&_post {
			padding: 12px 0;
		}
		
		&_info {
			
			&__item {
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		
		&_exit {
			top: auto;
			bottom: 0;
		}
	}
	
	@media (max-width: $screen-sm) {
		flex: 1 0 100%;
		max-width: 100%;
		
		&_info {
			margin-bottom: 24px;
		}
		
		&_exit {
			top: 0;
			left: auto;
			right: 0;
			bottom: auto;
			margin: auto;
		}
	}
	
	@media (max-width: $screen-xs) {
		
		&_title {
			margin-bottom: 0;
			padding-right: 40px;
			font-size: 18px;
			line-height: 22px;
		}
		
		&_post {
			font-size: 14px;
			line-height: 14px;
		}
		
		&_info {
			margin-top: 24px;
			margin-bottom: 12px;
			
			&__item {
				flex-wrap: wrap;
				margin-bottom: 20px;
				
				&-title {
					flex: 2 0 100%;
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 8px;
				}
			
				&-edit {
					margin-left: auto;
				}
			}
		}
		
		&_more {
			display: inline-block;
			position: absolute;
			top: 4px;
			right: 0;
			cursor: pointer;
			transition: .3s;
			
			&.is-active {
				transform: rotate(-180deg);
			}
		}
		
		&_exit {
			top: auto;
			left: 0;
			right: 0;
			bottom: -32px;
			margin: auto;
			text-align: center;
		}
	}
}