.sec-intro {
	position: relative;
	overflow: hidden;
	background: url("../../img/bg-pattern.png");
	background-size: contain;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&:before {
		background: $violet;
		opacity: 0.9;
	}

	&:after {
		background: linear-gradient(180deg, #7950F2 0%, rgba(121, 80, 242, 0) 0.01%, rgba(121, 80, 242, 0.15) 47.18%, #7950F2 86.96%, #7950F2 100%);
	}

	&_inner {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		min-height: 396px;
	}

	&_img {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
	}

	&_form {
		z-index: 3;
	}

	&_button {
		text-decoration: none !important;
		display: inline-flex;
		align-items: center;
		border-radius: 12px;
		border: 1px solid #FFF;
		position: absolute;
		z-index: 2;
		left: 0;
		top: 55px;
		padding: 9px 16px;
		color: #fff;

		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;

		&Wrapper {
			position: relative;
		}

		svg	{
			margin-right: 6px;
		}
	}

	@media (max-width: $screen-xl) {

		&_inner {
			flex-direction: column-reverse;
			justify-content: center;
			align-items: stretch;
			padding-top: 72px;
		}

		&_form {
			display: flex;
			justify-content: center;
		}

		&_img {
			position: relative;
			top: auto;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;

			img {
				max-width: 100%;
			}
		}

		&_button {
			position: relative;
			top: auto;
			width: 480px;

			&Wrapper {
				display: flex;
				justify-content: center;
				padding-top: 40px;
			}
		}
	}

	@media (max-width: $screen-sm) {
		background-size: cover;

		&_inner {
			padding-top: 48px;
		}

		&_img {
			margin: 24px -20px 0;

			img {
				max-width: calc(100% + 40px);
			}
		}
	}
}
