.wifi {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 16px;
	background: linear-gradient(118.77deg, #F4F3F8 -5.94%, #DCD6F2 96.6%);
	overflow-x: hidden;

	
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		background: url("../../img/wifi/bg.jpg") center no-repeat;
		background-size: cover;
	}
	
	&_block {
		position: relative;
		z-index: 2;
		top: 140px;
		left: 0;
		right: 0;
		max-width: 400px;
		width: 100%;
		display: flex;
		flex-direction: column;
		min-height: 370px;
		margin: auto;
		background: #FFFFFF;
		box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
		border-radius: 16px;
	}
	
	&_clouds {
		position: absolute;
		z-index: 1;
		top: 85px;
		left: 23%;
	}
	
	&_man {
		position: absolute;
		z-index: 1;
		left: 5%;
		bottom: 5%;
	}
	
	&_woman {
		position: absolute;
		z-index: 1;
		right: 10%;
		bottom: 10%;
	}
	
	@media (max-width: 1439px) {
		
		&_clouds {
			top: 96px;
			left: 2%;
		}
		
		&_man {
			left: -80px;
			bottom: 32px;
		}
		
		&_woman {
			right: -110px;
			bottom: 146px;
		}
	}
	
	@media (max-width: 767px) {
		
		&:after {
			display: none;
		}
		
		&_block {
			top: 76px;
		}
		
		&_clouds,
		&_man {
			display: none;
		}
		
		&_woman {
			bottom: 0;
		}
	}
}