.sec-school {
	padding: 48px 0;
	
	&_title {
		font-weight: 900;
		font-size: 48px;
		line-height: 72px;
		text-align: center;

		&_small {
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 48px;
			text-align: center;
			margin-bottom: 12px;
		}
	}
	
	&_description {
		max-width: 800px;
		margin: 0 auto;
		text-align: center;
	}
	
	&_useful {
		margin-top: 64px;
	}
	
	@media (max-width: $screen-xl) {
		 padding: 60px 0 32px;
		
		&_useful {
			margin-top: 48px;
		}
	}
	
	@media (max-width: $screen-xs) {
		padding: 24px 0 32px;
	}
}
