.site {

	&_wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background: $bgColor;
		overflow-x: hidden;
	}

	&_footer {
		margin-top: auto;
	}
}
