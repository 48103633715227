.useful {

	&_title {
		font-weight: 700;
		font-size: 28px;
		line-height: 42px;
	}

	&_list {
		display: flex;
		margin-top: 32px;
		margin-left: -16px;
		margin-right: -16px;

		.useful_item {
			flex: 1 1 calc(33.33% - 32px);
			max-width: calc(33.33% - 32px);
			margin: 0 16px;
		}
	}

	&_slider {
		display: none;
	}

	&_item {
		padding: 24px;
		background: #fff;
		border-radius: 16px;
		box-shadow: 0 6px 16px rgba(230, 235, 245, 0.8);

		&__title {
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
		}

		&__description {
			margin-top: 12px;
		}
	}

	@media (max-width: $screen-xs) {

		&_list {
			display: none;
		}

		&_item {
			padding: 16px;

			&__img {
				margin-right: 16px;
			}
		}

		&_slider {
			display: block;
			margin: 8px -4px;

			.useful_item {
				margin: 4px 4px;
				max-width: 209px;
				min-height: 270px;
			}
		}
	}
}
