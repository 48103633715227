.attention {
  position: static;
  padding: 16px;
  width: 100%;
  background: #FFFFFF;
  & .wrapper {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    max-width: 1219px;
    width: 100%;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
    & .content {
      font-size: 12px;
      position: relative;
      & .box {
        @media screen and (max-width: 529px) {
          left: 0;
          top: 39px;
        }

        @media screen and (max-width: 320px) {
          left: 0;
          top: 53px;
        }

        @media screen and (max-width: 282px) {
          left: 0;
          top: 68px;
        }

        position: absolute;
        width: 14px;
        height: 14px;
        left: 35px;
        top: 24px;
        background: rgb(255, 255, 255);
        border-radius: 2px;
        transform: rotate(45deg);
        z-index: 1;
      }
      & .text {
        color: #0055CB;
        display: inline-block;
      }
    }
  }
}
