.not-found {
	display: flex;
	align-items: center;

	&_info {
		flex: 2 1 420px;
		max-width: 420px;
		margin-right: 22px;
	}

	&_img {
		flex: 1 1 auto;
		margin-left: auto;

		img {
			max-width: 100%;
		}
	}
	&_cursor {
		cursor: pointer;
	}

	&_title {
		margin-bottom: 32px;
		font-weight: 800;
		font-size: 32px;
		line-height: 48px;
	}

	&_description {
		margin-bottom: 32px;
		color: #5A5F77;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;

		span {
			display: inline-block;
			margin: 0 3px;
			color: #0B70FF;
		}
	}

	@media (max-width: $screen-sm) {
		flex-direction: column;
		align-items: flex-start;

		&_info {
			flex: 1 1 auto;
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 32px;
		}

		&_title {
			margin-bottom: 8px;
		}
	}

	@media (max-width: $screen-xs) {

		&_action {

			.btn-arrow {
				max-width: 100%;
				width: 100%;
			}
		}
	}
}
