.profile-devices {
	
	&_title {
		margin-bottom: 20px;
		font-size: 32px;
		font-weight: 900;
		line-height: 32px;
	}
	
	&_subtitle {
		margin-bottom: 40px;
		color: #7B819B;
		font-size: 16px;
		line-height: 22px;
	}
	
	&_item {
		margin-bottom: 40px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&__head {
			display: flex;
			align-items: center;
			padding: 6px 16px;
			background-color : #F2F3F5;
			border-radius: 4px;
		}
		
		&__info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 16px;
			padding-right: 16px;
		}
		
		&__name {
			font-size: 16px;
			line-height: 20px;
		}
		
		&__time {
			margin-left: auto;
			color: #7B819B;
			font-size: 16px;
			line-height: 20px;
		}
		
		&__delete {
			position: relative;
			display: flex;
			align-items: flex-start;
			margin-left: 24px;
			cursor: pointer;
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: -14px;
				bottom: 0;
				width: 4px;
				height: 4px;
				margin: auto;
				background-color: #CDCFD9;
				border-radius: 100%;
			}
		}
		
		&__type {
			
			.radio_label {
				margin-left: 24px;
			}
		}
	}
	
	&_type {
		margin-bottom: 24px;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}
	
	@media (max-width: $screen-md) {
		margin-bottom: -88px;
	}
	
	@media (max-width: $screen-sm) {
		margin-bottom: 0;
	}
	
	@media (max-width: $screen-xs) {
		margin-left: -16px;
		margin-right: -16px;
		padding-bottom: 16px;
		
		&_title {
			margin-bottom: 16px;
			padding-left: 30px;
			font-size: 18px;
			line-height: 18px;
		}
		
		&_subtitle {
			margin-bottom: 24px;
			padding-left: 30px;
			font-size: 13px;
			line-height: 18px;
			
			br {
				display: none;
			}
		}
		
		&_item {
			margin-bottom: 32px;
			
			&__head {
				position: relative;
				flex-wrap: wrap;
				padding: 7px 45px 7px 30px;
				border-radius: 0;
			}
			
			&__info {
				flex-wrap: wrap;
				padding-top: 0;
				padding-right: 0;
			}
			
			&__name,
			&__time {
				flex: 1 0 100%;
				max-width: 100%;
				font-size: 13px;
			}
			
			&__delete {
				position: absolute;
				top: 10px;
				right: 24px;
				
				&:after {
					display: none;
				}
			}
			
			&__service,
			&__type {
				flex: 1 0 100%;
				padding: 12px 0;
				border-bottom: 1px solid #E6E7EC;
			}
			
			&__service {
				padding-left: 10px;
			}
			
			&__type {
				padding-left: 30px;
				
				.radio_label {
					margin-right: 16px;
					margin-left: 0;
				}
			}
		}
		
		&_type {
			margin-bottom: 16px;
			padding-left: 30px;
			font-size: 13px;
		}
	}
}