.callback {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding: 32px;

	&_block {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 370px;
		text-align: center;

		&_main {
			max-width: none;
		}
	}

	&_img {
		margin: 32px;
	}

	&_title {
		margin-bottom: 24px;
		color: #111;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}

	&_description {
		color: #181920;
		font-size: 16px;
		line-height: 24px;
	}
}
