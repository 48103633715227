.auth {

	&-modal {
		padding: 40px 330px 0 32px;
		//background: url("../../img/images/lemurs.svg") right 94px no-repeat;

		&_title {
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: 700;
			line-height: 20px;
		}

		&_description {
			color: #7B819B;
			font-size: 16px;
			line-height: 20px;
		}

		&_devices {

			&__inner {
				display: flex;

				.current-devices {
					flex: 2 0 293px;
					max-width: 293px;
					margin-right: 8px;
					z-index: 2;

					.auth-modal_devices__title {
						margin-left: 4px;
					}

					.auth-modal_devices__item {
						flex: 2 0 293px;
						max-width: 293px;
						margin-right: 0;
						padding-right: 8px;
						border-right: 1px solid #eee;
					}
				}

				.other-devices {
					flex: 1 1 auto;
					width: calc(100% - 301px);
					margin-left: -301px;

					.auth-modal_devices__title {
						margin-left: 309px;
						white-space: nowrap;
					}

					.auth-modal_devices__item:first-child {
						margin-left: 309px;
					}
				}
			}

			&__title {
				margin-bottom: 8px;
				color: #7B819B;
				font-size: 14px;
				line-height: 14px;
			}

			&__list {
				display: flex;
				flex-wrap: wrap;
			}

			&__item {
				flex: 1 0 285px;
				max-width: 285px;
				margin: 0 8px 16px;
			}
		}
	}

	&-form {

		&_tab {

			.tab {
				margin-bottom: 16px;
			}
		}

		&_field {

			.input {
				padding-bottom: 8px;
			}
		}

		&_save {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 16px;
			margin-bottom: 24px;
		}

		&_forget {
			color: #0055CB;
			line-height: 19px;
			cursor: pointer;
		}

		&_action {
			margin-bottom: 24px;
		}

		&_or {
			position: relative;
			margin-bottom: 24px;
			text-align: center;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				margin: auto;
				background-color: #eee;
			}

			&__text {
				position: relative;
				z-index: 2;
				display: inline-block;
				padding: 0 20px;
				color: #7B819B;
				font-size: 14px;
				background-color: #fff;
			}
		}

		&_other-login {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 16px;

			&__item {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 48px;
				margin: 0 4px;
				padding: 0 16px;
				background: #F4F3F8;
				border-radius: 8px;
			}
		}
	}

	@media (max-width: $screen-xl) {

		&-modal {

			&_devices {

				&__inner {

					.current-devices {
						flex: 1 0 calc(33.33% - 8px);
						max-width: calc(33.33% - 8px);

						.auth-modal_devices__item {
							flex: 1 0 100%;
							max-width: 100%;
						}
					}

					.other-devices {
						width: calc(100% - 33.33%);
						margin-left: -33.33%;

						.auth-modal_devices__title {
							margin-left: calc(33.33% + 8px);
						}

						.auth-modal_devices__item:first-child {
							margin-left: calc(33.33% + 8px);
						}
					}
				}

				&__item {
					flex: 1 0 calc(33.33% - 16px);
					max-width: calc(33.33% - 16px);
				}
			}
		}
	}

	@media (max-width: $screen-lg) {

		&-modal {
			padding: 40px 32px 32px;
			background: none;
		}
	}

	@media (max-width: $screen-md) {

		&-modal {

			&_devices {

				&__inner {

					.current-devices {
						flex: 1 0 calc(33.33% - 4px);
						max-width: calc(33.33% - 4px);
						margin-right: 4px;

						.auth-modal_devices__item {
							padding-right: 4px;
						}
					}

					.other-devices {

						.auth-modal_devices__title {
							margin-left: calc(33.33% + 4px);
						}

						.auth-modal_devices__item:first-child {
							margin-left: calc(33.33% + 4px);
						}
					}
				}

				&__item {
					flex: 1 0 calc(33.33% - 8px);
					max-width: calc(33.33% - 8px);
					margin: 0 4px 8px;
				}
			}
		}

		&-form {

			&_other-login {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: $screen-sm) {

		&-modal {
			padding: 32px 32px 4px;

			&_devices {

				&__inner {

					.current-devices {
						flex: 1 0 calc(50% - 4px);
						max-width: calc(50% - 4px);
					}

					.other-devices {
						width: calc(100% - 50%);
						margin-left: -50%;

						.auth-modal_devices__title {
							margin-left: calc(50% + 8px);
						}

						.auth-modal_devices__item:first-child {
							margin-left: calc(50% + 8px);
						}
					}
				}

				&__list {
					margin-bottom: 28px;
				}

				&__item {
					flex: 1 0 calc(50% - 8px);
					max-width: calc(50% - 8px);
					margin: 0 4px 8px;
				}
			}
		}
	}

	@media (max-width: $screen-xs) {

		&-modal {
			padding: 48px 16px 4px;

			&_title {
				font-size: 16px;
			}

			&_description {
				margin-bottom: 24px;

				br {
					display: none;
				}
			}

			&_devices {

				&__inner {
					flex-wrap: wrap;

					.current-devices {
						flex: 1 0 100%;
						max-width: 100%;

						.auth-modal_devices__item {
							padding-right: 0;
							border-right: 0;
						}
					}

					.other-devices {
						width: 100%;
						margin-left: 0;

						.auth-modal_devices__title {
							margin-left: 0;
						}

						.auth-modal_devices__item:first-child {
							margin-left: 0;
						}
					}
				}

				&__list {
					margin: 0 0 24px;
				}

				&__item {
					flex: 1 0 100%;
					max-width: 100%;
					margin: 0 0 8px;
				}
			}
		}

		&-form {

			&_action {

				.btn {
					max-width: 100%;
				}
			}
		}
	}
}