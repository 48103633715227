.client {
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;

	&-container {
		position: relative;
		max-width: 912px;
		width: 100%;
		margin: 0 auto;
		padding-left: 32px;
		padding-right: 32px;

		@media (max-width: $screen-xs) {
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	&-header {
		//background-color: #00B9C6;

		&_inner {
			display: flex;
			height: 210px;
			padding: 24px 0;
		}
	}

	&-main {
		position: relative;
		margin-top: -177px;
		padding: 40px 0;

		&_inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -12px;
		}

		&_block {
			margin-bottom: 12px;
			background-color: #fff;
			box-shadow: 0 4px 32px rgba(16, 8, 46, 0.1);
			border-radius: 16px;
		}
	}

	&-footer {
		position: relative;
		z-index: 10;
		margin-top: auto;
		padding: 32px;
		background-color: #2B3858;

		&_inner {
			max-width: 1170px;
			margin: auto;
		}

		&_top {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			margin-bottom: 20px;
		}

		&_top_main {
			margin-top: 10px;
		}

		&_nav {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			&__item {
				display: inline-block;
				margin-right: 32px;
				color: #fff;
				font-size: 16px;
				line-height: 24px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&_copyright {
			display: flex;
			align-items: flex-start;

			&__icon {
				margin-right: 10px;
			}

			&__description {
				opacity: .3;
				color: #E0EDFF;
				font-size: 14px;
				line-height: 20px;
			}
		}

		&_contact {

			display: flex;
			@media (max-width: 592px) {
				flex-direction: column;
			}
			//align-items: center;

			&__btn {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				height: 40px;
				min-width: 150px;
				margin-left: 16px;
				padding: 0 20px;
				color: #fff;
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				background-color: transparent;
				border: 1px solid rgb(176, 179, 195);
				border-radius: 8px;
				cursor: pointer;

				&:hover {
					color: #fff;
					text-decoration: none;
					background-color: rgb(123, 129, 155);
					border-color: rgb(176, 179, 195);
					box-shadow: rgba(0, 0, 0, 0.1) 0 8px 12px;
				}
			}
		}

		&_social {
			display: flex;
			@media (max-width: 420px) {
				flex-direction: column;
			}

			&__item {
				margin: 0 12px 0 0;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	@media (max-width: 767px) {

		&-footer {

			&_top {
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				margin-bottom: 24px;
			}

			&_nav {
				margin-bottom: 16px;

				&__item {

					&:last-child {
						margin-right: 0;
					}
				}
			}

			&_contact {
				justify-content: flex-start;
			}

			&_social {

				&__item {

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}

.footer {
	&_kop {
		margin-right: 47px;
		@media (max-width: 575px) {
			margin-right: 5px;
		}
	}
	&_flex {
		display: flex;
		flex-direction: column;
	}
	&_span_clas {
		line-height: 24px;
		display: flex;
		align-items: center;
		color: #FFFFFF;
		opacity: 0.5;
		white-space: nowrap;
		margin-bottom: 10px;

		&_nowrap {
			white-space: nowrap;
		}
		&_item {
			margin-left: 12px;
			color: #FFFFFF;
		}
	}
}

.wrap_partner {
	margin-bottom: 12px;
}

.subscribe {
	&_wrapper {
		display: inline-flex;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		align-items: center;
		color: #000000;
	}
	&_logo {
		width: 20px;
		height: 20px;
		margin: 0 8px;
		background: url("../../img/VK.svg") center no-repeat;
	}
}

.partner {
	&_content {
		margin-left: 24px;
	}
	&_left {
		display: flex;
	}
	&_block {
		display: flex;
		justify-content: space-between;
		margin-bottom: 48px;
		&_logo {
			width: 50px;
			height: 45px;
			background: url("../../img/icons/sferum.png") center no-repeat;
		}
		&_title {
			color: #000000;
			font-weight: 700;
			font-size: 18px;
			line-height: 28px;
		}
		&_description {
			color: #7B819B;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
		}
		&_buttonBlock {}
	}

	@media (max-width: $screen-xss) {
		&_block {
			flex-direction: column;
			margin-bottom: 0px;
		}

		&_left {
			margin-bottom: 31px;
		}
	}

	&_modal {
		&_description {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			color: #5A5F77;
			margin-bottom: 32px;
			align-items: center;
			justify-content: center;
		}
		&_image {
			height: 70px;
			margin-bottom: 40px;
			background: url("../../img/sferum.svg") center no-repeat;
		}
		&_imageRgn {
			height: 70px;
			margin-bottom: 40px;
			background: url("../../img/sferum_rgn.svg") center no-repeat;
		}
		&_actions {
			display: flex;
		}

		@media (max-width: $screen-xs) {
			&_actions {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}
}


.flow {
	&_actions {
		display: flex;
		flex-direction: row-reverse;
	}
	&_modal {
		&_divider {
			margin-bottom: 12px;
		}
		&_description {
			font-weight: 400;
			font-size: 15px;
			line-height: 24px;
			display: flex;
			color: #000000;
			text-align: left;
			justify-content: left;
		}
		li {
			left: -12px;
			position: relative;
		}
		&_link-blue {
			color: #4C6EF5;
			font-size: 15px;
			display: contents;
			cursor: pointer;
		}


		@media (max-width: $screen-xs) {
			&_actions {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}
}

