.wifi-help {
	display: flex;
	flex-direction: column;
	
	&_item {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		&__icon {
			margin-right: 8px;
		}
		
		&__title {
			color: #001129;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__link {
			width: 20px;
			height: 20px;
			margin-left: auto;
			background: url("../../img/wifi/diagonal-arrow-right-up.svg") center no-repeat;
			cursor: pointer;
		}
	}
}