.client-devices {

	&_block {
		position: relative;
		padding: 24px;
		background: #fff;
		border-radius: 16px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	}
	
	&_title {
		margin-bottom: 8px;
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
	}
	
	&_description {
		margin-bottom: 24px;
		color: #7B819B;
		font-size: 14px;
		line-height: 20px;
	}
	
	&_list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -4px 24px;
		
		&__title {
			flex: 2 0 100%;
			max-width: 100%;
			margin: 0 4px 8px;
			color: #7B819B;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&_item {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 1 calc(50% - 8px);
		max-width: calc(50% - 8px);
		min-height: 290px;
		margin: 0 4px 8px;
		border: 1px solid #E8E8EF;
		border-radius: 16px;
		
		&.devices-tied {
			border-color: #3DAF3A;
		}
		
		&__info {
			position: absolute;
			top: 0;
			left: 100%;
			z-index: 10;
			width: 100%;
			display: flex;
			flex-direction: column;
			max-width: calc(100% - 8px);
			transform: translateX(16px);
			min-height: 210px;
			background-color: #4794FF;
			border-radius: 16px;
			box-shadow: 0 16px 32px rgba(21, 74, 122, 0.1);
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: -8px;
				bottom: 0;
				transform: rotate(45deg);
				width: 20px;
				height: 20px;
				margin: auto;
				background-color: #4794FF;
				border-radius: 4px;
			}
			
			.devices-info {
				position: relative;
				display: flex;
				flex-direction: column;
				flex: 2 0 100%;
				padding: 24px;
				
				&_close {
					position: absolute;
					top: 24px;
					right: 24px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					width: 32px;
					height: 32px;
					background: rgba(255, 255, 255, 0.1);
					border-radius: 100%;
					cursor: pointer;
					transition: .3s;
					
					&:hover {
						background: rgba(255, 255, 255, 0.2);
					}
				}
				
				&_title {
					margin-bottom: 10px;
					padding-right: 28px;
					color: #fff;
					font-size: 16px;
					line-height: 24px;
				}
				
				&_action {
					margin-top: auto;
				}
				
				&_btn {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					height: 40px;
					padding: 0 24px;
					color: #0055CB;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					background: #fff;
					border: none;
					border-radius: 8px;
					cursor: pointer;
					transition: .3s;
					
					&:hover {
						box-shadow: 0 0 15px rgba(0,0,0,.15);
					}
				}
			}
		}
		
		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 20px 18px;
		}
		
		&__body {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			padding: 0 24px 24px;
		}
		
		&__change {
			display: flex;
			align-items: center;
		}
		
		&__edit {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 32px;
			margin-right: 8px;
			padding: 0 12px;
			color: #0C0D10;
			font-size: 16px;
			line-height: 24px;
			background-color: #F4F3F8;
			border-radius: 100px;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				text-decoration: none;
				box-shadow: 0 0 5px rgba(0,0,0,.15);
			}
			
			i {
				margin-right: 4px;
			}
		}
		
		&__delete {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			background-color: #F4F3F8;
			border-radius: 100%;
			cursor: pointer;
			transition: .3s;
			
			&:hover {
				text-decoration: none;
				box-shadow: 0 0 5px rgba(0,0,0,.15);
			}
		}
		
		&__form {
			margin-top: auto;
			
			&-line {
				margin-bottom: 8px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				.input {
					padding-bottom: 0;
				}
			}
		}
		
		&__name {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
		}
		
		&__post {
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__time {
			margin-top: auto;
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
		}
		
		&__bottom {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 2 0 55px;
			max-height: 55px;
			margin-top: auto;
			border-top: 1px solid #F4F3F8;
		}
		
		&__link {
			display: inline-flex;
			align-items: center;
			cursor: pointer;
			color: #0055CB;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			
			i {
				margin-right: 6px;
			}
		}
		
		&__action {
			position: absolute;
			top: 24px;
			right: 24px;
			display: flex;
			align-items: center;
		}
		
		&__cancel {
			color: #7B819B;
			font-size: 16px;
			line-height: 24px;
			cursor: pointer;
		}
		
		&__save {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: 32px;
			margin-left: 16px;
			padding: 0 12px;
			color: #fff;
			font-size: 16px;
			line-height: 24px;
			background-color: #0055CB;
			border-radius: 100px;
			cursor: pointer;
			
			&:hover {
				text-decoration: none;
				box-shadow: 0 0 15px rgba(0,0,0,.25);
			}
		}
	}
	
	@media (max-width: $screen-sm) {
		
		&_block {
			padding: 16px;
		}
		
		&_description {
			
			br {
				display: none;
			}
		}
		
		&_list {
			margin: 0;
		}
		
		&_item {
			flex: 1 1 100%;
			max-width: 100%;
			margin: 0 0 8px;
			
			&__body {
				padding: 0 16px 16px;
			}
			
			&__info {
				left: 0;
				bottom: 100%;
				transform: translate(0, -100%);
				margin-top: -16px;
				box-shadow: 0 16px 32px rgba(21, 74, 122, 0.1);
				
				&:before {
					top: auto;
					left: 0;
					right: 0;
					bottom: -8px;
				}
				
				.devices-info {
					padding: 18px;
					
					&_close {
						top: 18px;
						right: 18px;
					}
				}
			}
			
			&__action {
				position: relative;
				top: 0;
				right: 0;
				margin: 0 -2px;
				padding: 0 16px 16px;
			}
			
			&__cancel,
			&__save {
				flex: 1 1 calc(50% - 4px);
				max-width: calc(50% - 4px);
				height: 36px;
				margin: 0 2px;
			}
			
			&__cancel {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				color: #181920;
				background-color: #F4F3F8;
				border-radius: 100px;
				
				&:hover {
					text-decoration: none;
					box-shadow: 0 0 15px rgba(0,0,0,.25);
				}
			}
		}
	}
}