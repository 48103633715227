.tab {
	
	&_list {
		display: flex;
		align-items: center;
		padding: 4px 2px;
		border-radius: 16px;
		background-color: rgba(255,255,255,.1);
	}
	
	&_item {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 0;
		height: 40px;
		margin: 0 2px;
		padding: 0 30px;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
		border-radius: 12px;
		cursor: pointer;
		transition: .3s;
		
		&:hover {
			background-color: rgba(255,255,255,.1);
		}
		
		
		&.is-active {
			color: #000;
			background-color: #fff;
		}
	}
}