.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	//padding: 0 95px;
	width: 100%;
	max-width: 400px;
	color: #fff;
	font-size: 16px;
 	font-weight: 700;
	line-height: 19px;
	background: #0055CB;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: .3s;
	
	&:hover {
		background: #0049AD;
		text-decoration: none;
	}
	
	&:focus,
	&:active {
		outline: none;
	}
	&_disabled {
		color: #7c839e;
		background: #e5e5e5;
		cursor: not-allowed;

		display: flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		//padding: 0 95px;
		width: 100%;
		max-width: 400px;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		border: none;
		border-radius: 8px;
		transition: .3s;
	}
}

.btn-gray {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 400px;
	height: 40px;
	padding: 0 16px;
	color: #0055CB;
	font-size: 16px;
	background: #F4F3F8;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: .3s;
	
	&:hover {
		color: #fff;
		background: #0049AD;
		text-decoration: none;
	}
	
	&:focus,
	&:active {
		outline: none;
	}
}

.btn-outline {
	display: inline-flex;
	align-items: center;
	width: 100%;
	max-width: 400px;
	height: 48px;
	padding: 0 16px;
	color: #181920;
	font-size: 16px;
	line-height: 16px;
	background-color: transparent;
	border: 1px solid #eee;
	border-radius: 8px;
	cursor: pointer;
	transition: .3s;
	
	&:hover {
		background-color: #F4F3F8;
		text-decoration: none;
	}
	
	&:focus,
	&:active {
		outline: none;
	}
	
	i {
		margin-right: 12px;
	}
}

.btn-store {
	display: inline-flex;
	align-items: center;
	height: 48px;
	padding: 8px 40px 8px 24px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	
	&:hover {
		text-decoration: none;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	}
	
	&_icon {
		display: inline-block;
		margin-right: 14px;
	}
	
	&_title {
		display: block;
		color: #000;
		font-size: 14px;
		line-height: 14px;
	}
	
	&_app {
		display: block;
		color: #000;
		font-size: 16px;
		font-weight: 700;
		line-height: 16px;
	}
}

.btn-white {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 100%;
	max-width: 400px;
	padding: 0 16px;
	color: #181920;
	font-size: 16px;
	line-height: 19px;
	background: #fff;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	transition: .3s;

	&:hover {
		color: #fff;
		background: #0049AD;
		text-decoration: none;

		i {
			background: url("../../img/icons/arrow-btn-white.svg") center no-repeat;
		}
	}

	&:focus,
	&:active {
		outline: none;
	}

	i {
		margin: 0 10px;
	}
}

.btn-arrow {

}